import Question from "./question";
import Answer from "./answer";
import { useState } from "react";
import { Input } from "antd";
import AnswerDrawer from "./answer-drawer";

const TextInput = ({ data, answeredLabel, setAnswer, inProcess }) => {
  const [isOpen, setOpen] = useState();
  const [value, setValue] = useState();

  const handleAnswer = () => {
    if (inProcess) return;
    setAnswer(answeredLabel);
  };

  const handleUpdateValue = (e) => {
    setValue(e.target.value);
  };

  const handleClose = () => setOpen(false);

  const closeAnswerModal = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    if (!value.length) return;
    setAnswer({ answer: value });
    closeAnswerModal();
  };

  return (
    <div className="multiple-choice">
      <Question text={data.message} />
      <div className="multiple-choice-answers">
        {answeredLabel ? (
          <Answer
            text={answeredLabel}
            locked={false}
            selected={answeredLabel}
            onClick={() => handleAnswer()}
          />
        ) : (
          <Answer
            key={`Input answers${Math.random()}`}
            text={data.placeholder ?? "Enter response…"}
            onClick={() => setOpen(true)}
          />
        )}
      </div>

      <AnswerDrawer
        open={isOpen}
        onClose={handleClose}
        title={data.message}
        submit={handleSubmit}
        height={260}
      >
        <div className="open-text-drawer-box">
          <Input.TextArea
            rows={{ min: 1 }}
            defaultValue={answeredLabel || value}
            onChange={(e) => handleUpdateValue(e)}
            placeholder={data.placeholder ?? "Enter response…"}
            className="open-text-drawer-input"
          />
        </div>
      </AnswerDrawer>
    </div>
  );
};

export default TextInput;
