import { CloseOutlined } from "@ant-design/icons";
import { Checkbox, Dropdown, Tag } from "antd";
import { useState } from "react";

const SingleFilter = ({ field, onChange, values }) => {
  const [open, setOpen] = useState(true);

  const items = [
    { name: "Yes", id: true },
    { name: "No", id: false },
    { name: "Empty", id: null },
  ];

  const getDropdownItem = (key, item) => ({
    key,
    label: (
      <div
        key={key}
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => onChange(field.id, item.id)}
        role="presentation"
      >
        <Checkbox checked={values.includes(item.id)} />
        <span style={{ marginLeft: 10 }}>{item.name}</span>
      </div>
    ),
  });

  const filters = () =>
    items.map((item, index) => getDropdownItem(index, item));

  const generateTagString = () => {
    if (!values.length) return "";
    let string = "";

    for (let i = 0; i < values.length && i < 3; i = i + 1) {
      const { name } = items.filter((element) => element.id === values[i])[0];
      if (i !== 2) {
        string = i ? `${string}, ${name}` : name;
      } else {
        string = `${string}, ${values.length - 2} more`;
      }
    }

    return string;
  };

  const openDropdown = () => {
    setOpen(true);
  };

  const close = (visible) => {
    if (!visible) {
      setOpen(visible);
    }
  };

  return (
    <Dropdown
      menu={{ items: filters(), onClick: (e) => e.preventDefault() }}
      trigger={["click"]}
      overlayStyle={{ boxShadow: "0px 20px 140px 0px rgba(0, 0, 0, 0.15)" }}
      open={open}
      onOpenChange={(visible) => close(visible)}
    >
      <Tag
        style={{
          fontSize: 14,
          border: "none",
          background: "rgba(108, 108, 137, 0.08)",
          padding: "5px 10px",
          marginTop: 5,
          cursor: "pointer",
        }}
        onClick={openDropdown}
      >
        <span style={{ fontWeight: 600 }}>{field.name}: </span>
        <span style={{ color: "rgba(108, 108, 137, 1)" }}>
          {generateTagString()}
        </span>
        <span
          style={{
            cursor: "pointer",
            color: "rgba(178, 178, 199, 1)",
            marginLeft: 10,
          }}
          onClick={() => onChange(field.id)}
          role="presentation"
        >
          <CloseOutlined style={{ fontSize: 12 }} />
        </span>
      </Tag>
    </Dropdown>
  );
};

export default SingleFilter;
