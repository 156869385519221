import React, { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setUser } from "../../store/slices/user";
import axios from "axios";
import ApiAuth from "../../api/auth";
import "./main-layout-v2.css";

export const MainLayoutV2 = memo(({ children, sider }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUser);
  // const isAdmin = useSelector(selectIsAdmin);
  const token = localStorage.getItem("user_token");

  const kickUser = useCallback(() => {
    dispatch(setUser({}));
    localStorage.removeItem("user_token");
  }, [dispatch]);

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401 || error.response.status === 403) {
        kickUser();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    axios.defaults.headers = { Authorization: "Bearer " + token };

    if (token) {
      ApiAuth.loginByToken(token)
        .then((res) => {
          dispatch(setUser({ ...res.data.user, token }));
        })
        .catch(() => {
          kickUser();
        });
    }
  }, [token, dispatch, kickUser]);

  if (!currentUser.token) {
    return null;
  }

  return (
    <div
      className={"lf-main-layout"}
      style={{ minHeight: "100vh", overflow: "hidden" }}
    >
      {children}
    </div>
  );
});
