import { useTranslation } from "react-i18next";
import UserActivityStatus from "../../../../components/user-activity-status/user-activity-status";
import TableСomponent from "../../../../components/table/table";
import moment from "moment";

import { useState } from "react";

import SurveyDrawer from "./survey-drawer";

const SurveyTable = ({ surveys }) => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState();
  const { t } = useTranslation();
  const columns = [
    {
      title: t("subject_management.table.survey_name"),
      dataIndex: "name",
      render: (_, survey) => (
        <span style={{ fontWeight: 700 }}>
          {survey.OnboardingSurvey.title || "N/A"}
        </span>
      ),
      key: "id",
      width: "40%",
    },
    {
      title: t("subject_management.table.assigment_date"),
      dataIndex: "createdAt",
      render: (_, survey) => (
        <span>
          {`${moment
            .utc(survey.createdAt)
            .format("DD MMM YYYY, HH:mm")}, UTC` || "N/A"}
        </span>
      ),
      key: "cohort",
      width: "20%",
    },
    {
      title: t("subject_management.table.completion_date"),
      dataIndex: "completedAt",
      render: (_, survey) => (
        <span>
          {survey.completedAt
            ? `${moment
                .utc(survey.completedAt)
                .format("DD MMM YYYY, HH:mm")}, UTC`
            : "N/A"}
        </span>
      ),
      key: "adherence",
      width: "20%",
    },
    {
      title: t("subject_management.table.status"),
      dataIndex: "status",
      render: (_, survey) => <UserActivityStatus userStatus={survey.status} />,
      key: "points",
      width: "20%",
    },
  ];

  const openSurvey = (item) => {
    setDrawerData(item);
    setIsOpenDrawer(true);
  };

  const close = () => {
    setIsOpenDrawer(false);
  };

  return (
    <>
      <TableСomponent
        columns={columns}
        data={surveys}
        onHandleRowClick={openSurvey}
      />
      <SurveyDrawer
        isOpenDrawer={isOpenDrawer}
        close={close}
        drawerData={drawerData}
      />
    </>
  );
};

export default SurveyTable;
