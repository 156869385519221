import { memo } from "react";

const Dashboard = memo(() => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.72729 -0.0227051C0.760796 -0.0227051 -0.0227051 0.760798 -0.0227051 1.7273V7.48487C-0.0227051 8.45137 0.760799 9.23487 1.7273 9.23487H5.54548C6.51198 9.23487 7.29548 8.45137 7.29548 7.48487V1.72729C7.29548 0.760796 6.51197 -0.0227051 5.54548 -0.0227051H1.72729ZM1.47729 1.7273C1.47729 1.58922 1.58922 1.47729 1.72729 1.47729H5.54548C5.68355 1.47729 5.79548 1.58922 5.79548 1.72729V7.48487C5.79548 7.62294 5.68355 7.73487 5.54548 7.73487H1.7273C1.58922 7.73487 1.47729 7.62294 1.47729 7.48487V1.7273ZM1.72729 10.644C0.760796 10.644 -0.0227051 11.4275 -0.0227051 12.394V14.2727C-0.0227051 15.2392 0.760799 16.0228 1.7273 16.0228H5.54548C6.51198 16.0228 7.29548 15.2392 7.29548 14.2727V12.394C7.29548 11.4275 6.51197 10.644 5.54548 10.644H1.72729ZM1.47729 12.394C1.47729 12.2559 1.58922 12.144 1.72729 12.144H5.54548C5.68355 12.144 5.79548 12.2559 5.79548 12.394V14.2727C5.79548 14.4108 5.68355 14.5227 5.54548 14.5227H1.7273C1.58922 14.5227 1.47729 14.4108 1.47729 14.2727V12.394ZM8.70457 1.7273C8.70457 0.760798 9.48807 -0.0227051 10.4546 -0.0227051H14.2727C15.2392 -0.0227051 16.0228 0.760796 16.0228 1.7273V4.57578C16.0228 5.54228 15.2392 6.32578 14.2727 6.32578H10.4546C9.48807 6.32578 8.70457 5.54228 8.70457 4.57578V1.7273ZM10.4546 1.47729C10.3165 1.47729 10.2046 1.58922 10.2046 1.7273V4.57578C10.2046 4.71385 10.3165 4.82578 10.4546 4.82578H14.2727C14.4108 4.82578 14.5227 4.71385 14.5227 4.57578V1.7273C14.5227 1.58922 14.4108 1.47729 14.2727 1.47729H10.4546ZM10.4546 7.73487C9.48807 7.73487 8.70457 8.51837 8.70457 9.48487V14.2727C8.70457 15.2392 9.48807 16.0228 10.4546 16.0228H14.2727C15.2392 16.0228 16.0228 15.2392 16.0228 14.2727V9.48487C16.0228 8.51837 15.2392 7.73487 14.2727 7.73487H10.4546ZM10.2046 9.48487C10.2046 9.3468 10.3165 9.23487 10.4546 9.23487H14.2727C14.4108 9.23487 14.5227 9.3468 14.5227 9.48487V14.2727C14.5227 14.4108 14.4108 14.5227 14.2727 14.5227H10.4546C10.3165 14.5227 10.2046 14.4108 10.2046 14.2727V9.48487Z"
        fill="#212121"
      />
    </svg>
  );
});

export default Dashboard;
