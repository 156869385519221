import { UserOutlined } from "@ant-design/icons";
import { Avatar, Dropdown } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ApiAuth from "../../api/auth";
import { selectUser } from "../../store/slices/user";
import "./styles.css";
import EditProfileIcon from "../../assets/edit-profile.svg";
import ExitIcon from "../../assets/exit.svg";
import KeyIcon from "../../assets/key.svg";
import {
  setIsOpenProfile,
  setProfilePlacement,
  setProfileUser,
} from "../../store/slices/user_management";
import ChangePasswordForm from "../profile/forms/change-password-form";
import InviteUserModal from "../modals/invitations/user/invite-user";
import Profile from "../profile/profile";

const ProfileBar = () => {
  const user = useSelector(selectUser);
  // const [placement, setPlacement] = useState("right"); // ["bottomRight", "topRight"
  const [isOpenUserModal, setIsOpenUserModal] = useState(false);
  const [isOpenChangePasswordModal, setIsOpenChangePasswordModal] =
    useState(false);
  const { avatar } = user;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const navigation = useNavigate();

  const closePasswordChangeModal = () => {
    setIsOpenChangePasswordModal(false);
  };

  const closeInviteUserModal = () => {
    setIsOpenUserModal(false);
  };

  const logout = () => {
    localStorage.removeItem("user_token");
    ApiAuth.logout();
    navigation("/login");
  };

  const openProfile = () => {
    dispatch(setProfilePlacement("left"));
    dispatch(setProfileUser(user));
    dispatch(setIsOpenProfile(true));
  };

  const onClick = ({ key }) => {
    switch (key) {
      case "1":
        return openProfile();
      case "2":
        return setIsOpenChangePasswordModal(true);
      case "3":
        return logout();
      default:
        return null;
    }
  };

  const items = [
    {
      key: "1",
      label: "Edit Profile",
      icon: <img src={EditProfileIcon} alt="edit" />,
    },
    {
      key: "2",
      label: "Change Password",
      icon: <img src={KeyIcon} alt="key" />,
    },
    {
      key: "3",
      label: "Logout",
      icon: <img src={ExitIcon} alt="exit" />,
    },
  ];

  const avatarImage = avatar ? (
    <img src={avatar} alt="avatar" className="profile-bar__avatar" />
  ) : (
    <Avatar icon={<UserOutlined />} />
  );
  //
  // const userName = (
  //   <p className="profile-bar__name">{`${firstName} ${lastName}` || email}</p>
  // );

  return (
    <>
      <Profile />
      <InviteUserModal isOpen={isOpenUserModal} close={closeInviteUserModal} />
      <ChangePasswordForm
        t={t}
        isOpen={isOpenChangePasswordModal}
        close={closePasswordChangeModal}
      />
      <div className="profile-bar">
        <Dropdown
          menu={{
            items,
            onClick,
          }}
          placement="topRight"
          trigger={["click"]}
          overlayStyle={{ width: 245 }}
        >
          <div className="profile-bar__user-container" role="presentation">
            {avatarImage}
          </div>
        </Dropdown>
      </div>
    </>
  );
};

export default ProfileBar;
