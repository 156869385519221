import React from "react";
import "./styles.css";

const TreeDotsLoader = () => {
  return (
    <>
      <div className="bouncing-loader">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
};

export default TreeDotsLoader;
