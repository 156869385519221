import { MainLayoutV2 } from "../../components/main-layout-v2/main-layout-v2";
import { MainNavigation } from "../../components/main-navigation/main-navigation";
import { Sidebar } from "../../components/sidebar/sidebar";
import { memo, useMemo } from "react";
import { useLocation } from "react-router-dom";
import TaskManagement from "./task-management/task-management";
import UserManagament from "./user-management/user-managament";
import { ArticleManagement } from "./article-management/article-management";
import CategoryManagement from "./category-management/category-management";
import PlanManagement from "./plan-management/plan-management";
import CohortManagement from "./cohort-management/cohort-management";
import ScenarioManagement from "./scenario-management/scenario-management";
import LifePoints from "./life-points/life-points";
import OnboardingManagement from "./onboarding-management/onboarding-management";
import LifeFacts from "./life-facts/life-facts";

export const Administration = memo(() => {
  const { hash } = useLocation();
  const navigation = [
    {
      label: "Content management",
      type: "group",
      children: [
        {
          key: "/administration#task",
          label: "Tasks",
          default: true,
        },
        {
          key: "/administration#plan",
          label: "Plans",
        },
        {
          key: "/administration#lifepoints",
          label: "LifePoints",
        },
        {
          key: "/administration#category",
          label: "Categories",
        },
        {
          key: "/administration#cohort",
          label: "Cohorts",
        },
        {
          key: "/administration#articles",
          label: "Articles",
        },
        {
          key: "/administration#fitfacts",
          label: "FitFacts",
        },
        {
          key: "/administration#scenarios",
          label: "Scenarios",
        },
        {
          key: "/administration#onboarding",
          label: "Onboarding",
        },
      ],
    },
    {
      label: "User management",
      type: "group",
      children: [
        { key: "/administration#system-users", label: "System users" },
        { key: "/administration#disabled-users", label: "Blocked users" },
        { key: "/administration#pending-users", label: "Pending users" },
      ],
    },
  ];

  const content = useMemo(() => {
    switch (hash) {
      default:
      case "#task":
        return <TaskManagement />;
      case "#articles":
        return <ArticleManagement />;
      case "#category":
        return <CategoryManagement />;
      case "#plan":
        return <PlanManagement />;
      case "#cohort":
        return <CohortManagement />;
      case "#lifepoints":
        return <LifePoints />;
      case "#scenarios":
        return <ScenarioManagement />;
      case "#onboarding":
        return <OnboardingManagement />;
      case "#fitfacts":
        return <LifeFacts />;
      case "#pending-users":
      case "#disabled-users":
      case "#system-users":
        return <UserManagament />;
    }
  }, [hash]);

  return (
    <MainLayoutV2>
      <MainNavigation />
      <Sidebar items={navigation} title={"Administration"} />
      {content}
    </MainLayoutV2>
  );
});
