import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {},
  reducers: {
    setUser: (state, action) => ({
      ...state,
      ...action.payload,
      fullName:
          action?.payload?.firstName && action?.payload?.lastName
              ? `${action?.payload?.firstName} ${action?.payload?.lastName}`
              : '',
    }),
  },
});

export const { setUser } = userSlice.actions;

export const selectUser = state => state.user;

export const hasUserToken = () => {
  const token = localStorage.getItem('user_token');
  const isValidToken = token && token !== 'null';

  return isValidToken;
};

export const selectIsAdmin = state => state.user.role === 'admin';

export default userSlice.reducer;
