import moment from "moment";

const getDateByDiff = (date) => {
  if (date) {
    const time = moment(date).fromNow();
    return time;
  }

  return "—";
};

export default getDateByDiff;
