import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../store/slices/user";
import "./styles.css";
import LoginView from "./login-view";
import ApiAuth from "../../api/auth";
import axios from "axios";

function Login() {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [inProgress, setProgress] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const localToken = localStorage.getItem("user_token");

  useEffect(() => {
    if (localToken) {
      ApiAuth.loginByToken(localToken)
        .then((res) => {
          dispatch(setUser(res.data.user));
          navigation("/");
        })
        .catch(() => {
          localStorage.removeItem("user_token");
        });
    }
  }, [dispatch, navigation, localToken]);

  const login = () => {
    setProgress(true);

    const { email, password } = form.getFieldsValue();
    const credentials = { email, password };

    ApiAuth.login(credentials)
      .then((res) => {
        const { token, user } = res.data;
        if (token) {
          dispatch(setUser(user));
          localStorage.setItem("user_token", token);
          axios.defaults.headers = { Authorization: "Bearer " + token };
          navigation("/dashboard");
        } else {
          setErrorMsg(t("login.no_connect"));
        }
      })
      .catch((e) => {
        if (e.response.data?.error?.message) {
          setErrorMsg(e.response.data.error.message);
        }
      })
      .finally(() => {
        setProgress(false);
      });
  };

  return (
    <LoginView
      form={form}
      t={t}
      inProgress={inProgress}
      errorMsg={errorMsg}
      login={login}
    />
  );
}

export default Login;
