import DropdownFilters from "../../../components/dropdown-filters/dropdown-filters";
import Header from "../../../components/header/header";

const AuditTrailHeader = ({
  t,
  filterItems,
  updateFilterValue,
  exportTrail,
}) => {
  return (
    <Header
      title={t("trails.title")}
      filters={
        <DropdownFilters
          filtersBy={filterItems}
          updateFilterValue={updateFilterValue}
        />
      }
      create={{
        label: t("trails.export"),
        onClick: exportTrail,
      }}
    />
  );
};

export default AuditTrailHeader;
