import axios from "axios";

const get = () => axios.get("api/v1/scores");

const add = (points) => axios.post("api/v1/scores", points);

const update = (list) => axios.put(`api/v1/scores`, { list });

const remove = (id) => axios.delete(`api/v1/scores/${id}`);

const ApiLifePointsManagement = {
  get,
  add,
  update,
  remove,
};

export default ApiLifePointsManagement;
