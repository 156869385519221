import Answer from "./answer";
import Question from "./question";

const SingleChoice = ({ data, setAnswer, answeredLabel }) => {
  return (
    <div className="multiple-choice">
      <Question text={data.message} />
      <div className="multiple-choice-answers">
        {data.response.map((item) => (
          <Answer
            key={item.label}
            text={item.label}
            locked={answeredLabel && item.label !== answeredLabel}
            selected={answeredLabel && item.label === answeredLabel}
            onClick={() => setAnswer(item)}
          />
        ))}
      </div>
    </div>
  );
};

export default SingleChoice;
