import { nodeDefinitions } from "../configs/default-items-definition";
import { nodeItemsConfig } from "../configs/default-items-config";
import { useEffect, useState } from "react";

const useScenarioConfig = (tasks) => {
  const [normalizeNodesDefinitions, setNormalizeNodeDefinitions] = useState();
  const [nodesConfig, setNodesConfig] = useState();

  useEffect(() => {
    const configTasksList = tasks.map((task) => ({
      title: task.title,
      titleDescription: "Add task",
      icon: task.avatar?.split("#000000").join(task.avatarColor),
      type: "GenericNode",
      nodeType: `task:${task.id}`,
      bgColor: task.backgroundColor,
    }));

    const normalizeTasksNodes = nodeItemsConfig.map((node) => {
      if (node.title === "Tasks") {
        return { ...node, items: configTasksList };
      }

      return node;
    });

    setNodesConfig(normalizeTasksNodes);

    const nodeDefinitionsDeepCopy = JSON.parse(JSON.stringify(nodeDefinitions));

    let nodeDefinitionsTasks = nodeDefinitionsDeepCopy["task:*"];

    nodeDefinitionsTasks.validationSchema.properties.nodeType.enum =
      configTasksList.map((task) => task.nodeType);

    nodeDefinitionsTasks.nodeInfoContent[0].options = configTasksList.map(
      (task) => ({ label: task.title, value: task.nodeType })
    );

    nodeDefinitionsDeepCopy["task:*"] = nodeDefinitionsTasks;

    setNormalizeNodeDefinitions(nodeDefinitionsDeepCopy);
  }, [tasks, nodeItemsConfig, nodeDefinitions]);

  return { nodesConfig, nodesDefinitions: normalizeNodesDefinitions };
};

export default useScenarioConfig;
