import  { memo } from "react";
import Block, { BlockAdd, BlockSection } from "../../../../components/block/block";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Icon } from "../../../../components/icon/icon";
import { Button, Dropdown } from "antd";


export const PageListAdd = memo(({pages, onAdd, editMode})=>{
  return(
    <Dropdown
      trigger={'click'}
      menu={{
      items: pages.map((page)=>({
        key: page.id,
        label: page.title
      })),
      onClick: (e)=> onAdd(e.key)
    }}>
      <BlockAdd title={'Add page'} hide={!editMode} />
    </Dropdown>
  )
})

export const PageList = memo(({pages, editMode, onRemove})=>{
  if (!pages?.length){
    return null;
  }
  return (
    <div className={'lf-task-pages'} style={{paddingTop:16}}>
      <h4 style={{ marginBottom: 16 }}>Pages</h4>
      {pages.length ? (
        <Block>
          {pages.map((page)=>{
            return(
              <BlockSection className={'lf-task-pages-item'}>
                <div className={'lf-task-pages-item__img'}>
                  <LazyLoadImage
                    src={`${
                      process.env.REACT_APP_DEV_URL || document.location.origin
                    }/api/v1/pages/${page.id}/icon`}
                    effect="blur"
                    loading="lazy"
                    height={100}
                  />
                </div>
                <div>
                  <h5 className={'lf-task-pages-item__title'}>{ page.title }</h5>
                  <p className={'lf-task-pages-item__text'}>{ page.text?.length > 200 ? `${page.text.slice(0, 200)}...` : page.text}</p>
                </div>
                <div className={'lf-task-pages-item__remove'}>
                  {editMode && <Button onClick={()=>onRemove(page.id)} type={"text"} icon={<Icon name={"delete"} />}></Button>}

                </div>
              </BlockSection>
            )
          })}
        </Block>
      ) : null}
    </div>
  )
})
