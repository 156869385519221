import axios from "axios";
import toQueryString from "../common/utils/to-query-string";

const get = (query) => axios.get(toQueryString(`/api/v1/facts?`, query));

const create = (params) => axios.post(`/api/v1/facts`, params);

const update = (id, params) => axios.put(`/api/v1/facts/${id}`, params);

const remove = (id) => axios.delete(`/api/v1/facts/${id}`);

const getTags = () => axios.get("/api/v1/fact-tags");

const getCategories = () => axios.get("/api/v1/categories");

const ApiLifeFacts = {
  get,
  create,
  update,
  remove,
  getTags,
  getCategories,
};

export default ApiLifeFacts;
