import InfiniteScroll from "react-infinite-scroll-component";
import Table from "../table/table";
import { BlockComponent } from "../block/block";

const InfinityTable = ({
  data,
  fetchData,
  hasMore,
  tableColumns,
  isLoading = false,
  onHandleRowClick,
  before,
  after,
}) => {
  return (
    <InfiniteScroll
      dataLength={data?.length || 0}
      next={fetchData}
      hasMore={hasMore}
      height={window.innerHeight - 88}
    >
      <BlockComponent fixThead>
        {before}
        <Table
          columns={tableColumns}
          data={data}
          isLoading={isLoading}
          onHandleRowClick={onHandleRowClick}
        />
        {after}
      </BlockComponent>
    </InfiniteScroll>
  );
};

export default InfinityTable;
