// import { useTranslation } from "react-i18next";
import { useState } from "react";
import iconsCollection from "../../../../assets/icons.json";

const icons = iconsCollection.icons;

const getFieldByType = (key, value) => {
  if (key === "type" || key === "required") {
    return { [key]: value };
  }

  if (key === "answers") {
    return { answers: value.target.value.split(/\n/) };
  }

  return {
    [key]: value.target.value,
  };
};

export const useTaskEdit = ({
  task,
  createTask,
  updateTask,
  pages,
  cancelEditing,
  close,
}) => {
  const [title, setTitle] = useState(task?.title);
  const [description, setDescription] = useState(task?.description);
  const [categoryId, setCategoryId] = useState(task?.Category?.id);
  const [icon, setIcon] = useState({
    avatar: task?.avatar || icons[68].content,
    avatarColor: task?.avatarColor || "#1E6379",
    backgroundColor: task?.backgroundColor || "#B0E5F5",
  });
  // const [showIconPicker, setShowIconPicker] = useState();

  const [taskPages, setTaskPages] = useState(task?.pages || []);
  const [questions, setQuestions] = useState(
    task?.questions?.length ? task?.questions : []
  );

  const onReset = () => {
    setQuestions(task.questions || []);
    setDescription(task.description || []);
    setIcon({
      avatar: task?.avatar || icons[68].content,
      avatarColor: task?.avatarColor || "#1E6379",
      backgroundColor: task?.backgroundColor || "#B0E5F5",
    });
    setCategoryId(task?.Category?.id);
    setTitle(task?.title);
    setDescription(task?.description);
  };

  const updateFormFields = (questionKey, key) => (value) => {
    let updatedQuestions = questions ? [...questions] : [];

    updatedQuestions[questionKey] = {
      ...updatedQuestions[questionKey],
      ...getFieldByType(key, value),
    };

    setQuestions(updatedQuestions);
  };

  const remove = (idx) => {
    const list = questions
      .filter((q) => q.key !== idx)
      .map((q, index) => ({ ...q, key: index }));

    setQuestions(list);
  };

  const addQuestion = () => {
    const newList = [
      ...(questions || []),
      {
        key: questions?.length ? questions.length : 0,
        title: `Question`,
        type: "openText",
      },
    ];

    setQuestions(newList);
  };

  const create = () => {
    console.log("task.id", task.id, questions, task?.questions);
    if (!task.id) {
      createTask({
        id: task.id,
        title,
        description,
        categoryId,
        questions: (questions || []).map((q) => ({
          ...q,
          answers: q.type === "openText" ? [""] : q.answers,
        })),
        pages: taskPages,
        ...icon,
      });
    } else {
      updateTask(task.id, {
        ...task,
        title,
        description,
        questions: (questions || []).map((q) => ({
          ...q,
          text: q.title || q.text,
          answers:
            q.type === "openText"
              ? [""]
              : (q.answers || [])
                  .map((a) => {
                    if (typeof a === "string") return a;

                    return a.text;
                  })
                  .filter((item) => item && item !== ""),
        })),
        id: task.id,
        pages: taskPages,
        categoryId,
        ...icon,
      });
    }
  };

  // const updateCategory = (id) => {
  //   setCategoryId(id);
  // };

  const onIconSelect = (avatar) => {
    console.log(avatar);
    setIcon(avatar);
  };

  // const toogleIconPicker = () => {
  //   setShowIconPicker(!showIconPicker);
  // };

  // const onPageSelect = (value, item) => {
  //   if (!item.length) {
  //     setTaskPages([]);
  //     return;
  //   }
  //   const selectsPages = pages.filter((p) =>
  //     item.map((i) => i.key).includes(p.id)
  //   );
  //   setTaskPages(selectsPages);
  // };

  const onAddPage = (id) => {
    setTaskPages((prev) => {
      return [...prev, pages.find((page) => page.id === id)];
    });
  };

  const onRemovePage = (id) => {
    setTaskPages((prev) => {
      return prev.filter((page) => page.id !== id);
    });
  };

  const cancel = () => {
    if (task?.id) {
      onReset();
      cancelEditing();
      return;
    }
    close();
  };
  return {
    save: create,
    cancel: cancel,
    tile: {
      value: title,
      update: (value) => setTitle(value.target.value),
    },
    description: {
      value: description,
      update: (value) => setDescription(value.target.value),
    },
    questions: {
      value: questions,
      update: updateFormFields,
      add: addQuestion,
      remove,
    },
    pages: {
      value: taskPages,
      add: onAddPage,
      remove: onRemovePage,
    },
    icon: {
      value: icon,
      update: onIconSelect,
    },
    category: {
      value: categoryId,
      change: (value) => setCategoryId(value),
    },
  };
};
