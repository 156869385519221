import { Button, Drawer } from "antd";

const AnswerDrawer = ({
  children,
  title,
  open,
  onClose,
  submit,
  height,
  isValid = null,
  destroyOnClose,
}) => {
  return (
    <Drawer
      placement="bottom"
      open={open}
      onClose={onClose}
      getContainer={false}
      closable={false}
      height={height}
      destroyOnClose={destroyOnClose}
    >
      <p className="multiple-choice-drawer-title">{title}</p>
      {children}
      <div className="multiple-choice-submit-box">
        <Button
          type="primary"
          // disabled={isValid === null ? false : isValid ? false : true}
          className="multiple-choice-submit-btn"
          onClick={submit}
        >
          Submit
        </Button>
      </div>
    </Drawer>
  );
};

export default AnswerDrawer;
