import { useState } from "react";

const useSearch = () => {
  const [search, setSearch] = useState();

  const handleUpdateSearchValue = (e) => {
    const { value } = e.target;
    if (value && value.trim().length) {
      setSearch(value);
    } else {
      setSearch(null);
    }
  };

  return { search, handleUpdateSearchValue };
};

export default useSearch;
