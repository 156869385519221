import "./card.css";

const Card = ({ children, title, extra }) => {
  return (
    <div className="card-container">
      <div className="card-header">
        <div className="card-header-left">
          <p className="card-title">{title}</p>
        </div>
        <div className="card-header-right">{extra}</div>
      </div>
      <div className="card-content">{children}</div>
    </div>
  );
};

export default Card;
