import { useTranslation } from "react-i18next";
import TableComponent from "../../../../../components/table/table";
import moment from "moment";
import Block from "../../../../../components/block/block";

const HealthDataTable = ({ data = [] }) => {
  console.log(data);
  const { t } = useTranslation();
  const columns = [
    {
      title: t("subject_management.table.date"),
      dataIndex: "date",
      render: (_, health) => {
        return (
          <span style={{ fontWeight: 600 }}>
            {moment(health.doneAt).format("DD MMMM")}
          </span>
        );
      },
      key: "date",
      width: "25%",
    },
    {
      title: t("subject_management.table.steps"),
      dataIndex: "steps",
      render: (_, health) => <span>{health.dailyStepCount || "N/A"}</span>,
      key: "steps",
      width: "25%",
    },
    {
      title: t("subject_management.table.heart"),
      dataIndex: "heart",
      render: (_, health) => <span>{health.restingHeartRate || "N/A"}</span>,
      key: "heart",
      width: "25%",
    },
    {
      title: t("subject_management.table.activeEnergy"),
      dataIndex: "activeEnergy",
      render: (_, health) => (
        <span>{health.activeEnergy ? health.activeEnergy : "N/A"}</span>
      ),
      key: "activeEnergy",
      width: "25%",
    },
  ];
  return (
    <Block fixThead>
      <TableComponent columns={columns} data={[...data].reverse()} />
    </Block>
  );
};

export default HealthDataTable;
