import moment from "moment";
import {
  Bar,
  BarChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";

const Chart = ({ data, average }) => {
  return (
    <ResponsiveContainer width={"100%"} height={160}>
      <BarChart data={data} barSize={12} barCategoryGap={1}>
        <Bar dataKey="value" fill="#B2B2C7" radius={[2, 2, 2, 2]} />
        <XAxis dataKey="name" hide={true} />
        <Tooltip
          labelFormatter={(date) => moment.utc(date).format("DD MMMM")}
          cursor={false}
        />
        <ReferenceLine
          y={average}
          fill="#6C6C89"
          stroke="#6C6C89"
          strokeWidth={3}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Chart;
