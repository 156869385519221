import { memo } from "react";

const User = memo(() => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.6501 4.49998C5.6501 2.82835 6.79262 1.64998 8.0001 1.64998C9.20757 1.64998 10.3501 2.82835 10.3501 4.49998C10.3501 6.1716 9.20757 7.34998 8.0001 7.34998C6.79262 7.34998 5.6501 6.1716 5.6501 4.49998ZM8.0001 0.349976C5.89387 0.349976 4.3501 2.30561 4.3501 4.49998C4.3501 6.69434 5.89387 8.64998 8.0001 8.64998C10.1063 8.64998 11.6501 6.69434 11.6501 4.49998C11.6501 2.30561 10.1063 0.349976 8.0001 0.349976ZM13.6501 13.5355C13.6501 14.0938 13.2861 14.4793 12.932 14.7163C12.5716 14.9577 12.1011 15.1328 11.6014 15.2629C10.5923 15.5256 9.28286 15.65 8.0001 15.65C6.71734 15.65 5.40791 15.5256 4.39883 15.2629C3.89908 15.1328 3.42862 14.9577 3.06818 14.7163C2.71414 14.4793 2.3501 14.0938 2.3501 13.5355C2.3501 12.2918 3.06398 11.2298 4.08929 10.5048C5.11623 9.77863 6.50096 9.34998 8.0001 9.34998C9.49923 9.34998 10.884 9.77863 11.9109 10.5048C12.9362 11.2298 13.6501 12.2918 13.6501 13.5355ZM3.79139 13.6361C3.69962 13.5746 3.66319 13.533 3.65022 13.5138C3.6584 12.8122 4.06351 12.1152 4.83984 11.5662C5.62254 11.0128 6.73781 10.65 8.0001 10.65C9.26239 10.65 10.3777 11.0128 11.1604 11.5662C11.9367 12.1152 12.3418 12.8122 12.35 13.5138C12.337 13.533 12.3006 13.5746 12.2088 13.6361C12.0224 13.7609 11.7116 13.8909 11.2738 14.0048C10.4079 14.2303 9.21734 14.35 8.0001 14.35C6.78286 14.35 5.59229 14.2303 4.72637 14.0048C4.28862 13.8909 3.97782 13.7609 3.79139 13.6361ZM3.64392 13.5017C3.6436 13.5017 3.64426 13.504 3.64685 13.5085C3.64553 13.5039 3.64423 13.5017 3.64392 13.5017ZM12.3563 13.5017C12.356 13.5017 12.3547 13.5039 12.3533 13.5085C12.3559 13.504 12.3566 13.5017 12.3563 13.5017Z"
        fill="#151515"
      />
    </svg>
  );
});

export default User;
