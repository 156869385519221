import { notification } from "antd";

export const useNotification = () => {
  const openNotification = ({
    type = "open",
    placement = "bottomRight",
    message,
    description,
  }) => {
    notification[type]({
      message,
      description,
      placement,
    });
  };

  return { openNotification };
};
export const useMessage = () => {
  const openNotification = ({
    type = "open",
    placement = "bottomRight",
    message,
    description,
  }) => {
    message[type]({
      message,
      description,
      placement,
    });
  };

  return { openNotification };
};
export default useNotification;
