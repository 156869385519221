import { Button, Divider, Form, Input } from "antd";
import { Link } from "react-router-dom";
import CompanyBrand from "../../components/company-brand/company-brand";
import "./styles.css";

function LoginView({ form, t, inProgress, errorMsg, login }) {
  return (
    <div className="auth">
      <div className="auth__container">
        <div className="auth__header">
          <CompanyBrand />
        </div>
        <Divider />
        <Form form={form} layout="vertical" onFinish={login}>
          <div className="auth__body">
            <Form.Item
              label={t("login.email")}
              name="email"
              data-testid="email-label"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: t("login.validation_msg.email"),
                },
                {
                  type: "email",
                  message: t("login.validation_msg.incorrect_email"),
                },
              ]}
            >
              <Input
                placeholder="email@example.com"
                size="large"
                disabled={inProgress}
                data-testid="email-input"
                autoComplete="off"
                maxLength={100}
              />
            </Form.Item>
            <Form.Item
              label={t("login.password")}
              name="password"
              data-testid="password-label"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: t("login.validation_msg.password"),
                },
                { type: "string", min: 8 },
              ]}
            >
              <Input.Password
                placeholder="• • • • •"
                size="large"
                disabled={inProgress}
                data-testid="password-input"
                autoComplete="off"
                aria-autocomplete="none"
                list="autocompleteOff"
                maxLength={30}
              />
            </Form.Item>

            {errorMsg && (
              <Form.Item>
                <p className="error-msg" data-testid="error">
                  {errorMsg}
                </p>
              </Form.Item>
            )}
          </div>
          <Form.Item>
            <div className="auth__footer">
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                disabled={inProgress}
                data-testid="login"
              >
                {t("login.login")}
              </Button>
              <Link to="/login/reset">
                <Button
                  htmlType="button"
                  type="link"
                  size="large"
                  disabled={inProgress}
                  data-testid="reset"
                >
                  {t("login.reset")}
                </Button>
              </Link>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default LoginView;
