import classNames from "classnames";

const Answer = ({ text, locked, selected, onClick }) => {
  return (
    <div
      className={classNames("answer-container", {
        "answer-container--locked": locked,
        "answer-container--selected": selected,
      })}
      onClick={onClick}
    >
      <p className="answer-text">{text}</p>
    </div>
  );
};

export default Answer;
