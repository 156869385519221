import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Card from "../../../../components/card/card";
import PlanCalendar from "./calendar";
import { Button, Input, Modal, Spin } from "antd";
import { Icon } from "../../../../components/icon/icon";
import UserActivityStatus from "../../../../components/user-activity-status/user-activity-status";
import TagsSelect from "../../../../components/tags-select/tags-select";
import ApiPlanManagement from "../../../../api/plan-management";
import { LoadingOutlined } from "@ant-design/icons";

const DiscardModalComponent = ({
  title,
  handleOk,
  handleCancel,
  isModalOpen,
}) => {
  return (
    <Modal
      title={title}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ danger: true }}
      okText="Discard changes"
      className="plan-page-action-modal"
    >
      <p>Are you sure you want to discard changes?</p>
    </Modal>
  );
};

const normalizeGroups = (group = []) => {
  return group.map((cohort) => ({
    id: cohort.id,
    label: cohort.EnrollmentFieldOption.value,
  }));
};

const PlanPage = ({
  plan,
  cohorts,
  scenarios,
  toList,
  refreshPlan,
  openNotification,
}) => {
  const { t } = useTranslation();
  const { title, id } = plan || {};

  const [planTasks, setPlanTasks] = useState([]);
  const [planScenarios, setPlanScenarios] = useState([]);
  const [versionStatus, setVersionStatus] = useState(null);
  const [weeklyScheduleId, setWeeklyScheduleId] = useState();
  const [planActiveCohortsIds, setPlanActiveCohortsIds] = useState([]);
  const [isOpenDiscardModal, setOpenDiscardModal] = useState();
  const [isLoading, setLoading] = useState(true);
  const [inProcess, setProcess] = useState(false);

  useEffect(() => {
    const { WeeklySchedules } = plan;
    const { status, tasks, id, scenarios } =
      WeeklySchedules?.filter(
        (ws) => ws.status === "pending" || ws.status === "activeCopy"
      )[0] || {};

    setVersionStatus(status);
    setPlanTasks(tasks?.sort((a, b) => a.position - b.position));
    setPlanScenarios(scenarios?.sort((a, b) => a.position - b.position));
    setPlanActiveCohortsIds(WeeklySchedules?.[0].group || []);
    setWeeklyScheduleId(id);
    if (plan) {
      setLoading(false);
    }
  }, [plan]);

  const onBack = () => {
    toList();
  };

  const addTaskToPlan = (task) => {
    setPlanTasks([...planTasks, task]);
  };

  const addScenarioToPlan = (scenario) => {
    setPlanScenarios([...planScenarios, scenario]);
  };

  const updatePlanTitle = (e) => {
    const { value } = e.target || {};
    ApiPlanManagement.updatePlan(id, { title: value });
  };

  const updatePlanCohorts = (value) => {
    const newCohortsList = value.map((v) => v.id);
    setPlanActiveCohortsIds(newCohortsList);
    ApiPlanManagement.updatePlanCohort(weeklyScheduleId, {
      groupScheduleIds: newCohortsList,
    }).finally(() => {
      refreshPlan();
    });
  };

  const updateTaskPlan = (plan) => {
    setPlanTasks(plan);
  };

  const updateScenariosPlan = (plan) => {
    setPlanScenarios(plan);
  };

  const discardPlan = () => {
    ApiPlanManagement.discardPlan(weeklyScheduleId).then(() => {
      refreshPlan();
    });

    openNotification({
      type: "success",
      message: <p>{t("plan_management.notification.discard")}</p>,
    });
  };

  const activatePlan = () => {
    setProcess(true);
    if (!inProcess) {
      ApiPlanManagement.activatePlan(weeklyScheduleId)
        .then(() => {
          refreshPlan();

          openNotification({
            type: "success",
            message: <p>{t("plan_management.notification.activated")}</p>,
          });
        })
        .catch((e) => {
          if (e?.response?.data?.error === "GroupsAlreadyUsed") {
            const cohorts = e?.response?.data?.groupsAlreadyUsed.reduce(
              (acc, cohort) => `${acc} ${cohort.EnrollmentFieldOption.value}`,
              ""
            );

            const plansTitles = e?.response?.data?.plans.reduce(
              (acc, plan) => `${acc} ${plan.title}`,
              ""
            );

            openNotification({
              type: "error",
              message: (
                <p>
                  {t("plan_management.notification.errorGroups", {
                    cohorts,
                    plans: plansTitles,
                  })}
                </p>
              ),
            });
          }
        })
        .finally(() => {
          setProcess(false);
        });
    }
  };

  const openDiscardModal = () => {
    setOpenDiscardModal(true);
  };

  const closeDiscardModal = () => {
    setOpenDiscardModal(false);
  };

  const handleDiscardOk = () => {
    discardPlan();
    closeDiscardModal();
  };

  const handleDiscardCancel = () => {
    closeDiscardModal();
  };

  return (
    <div className="plan-page">
      {isLoading ? (
        <div className="plan-page-loading-box">
          <Spin
            className="plan-page-loading"
            indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
          />
        </div>
      ) : (
        <>
          <div className="plan-page-header">
            <div className="plan-page-header-right">
              <Button
                icon={<Icon name={"arrow-left"} />}
                style={{ marginRight: 16 }}
                onClick={onBack}
              />
              <Input
                defaultValue={title}
                bordered={false}
                onChange={updatePlanTitle}
                className="plan-page-name"
              />
            </div>
            <div className="plan-page-header-left">
              {versionStatus === "pending" ? (
                <>
                  <Button
                    onClick={openDiscardModal}
                    className="plan-page-header-left-btn"
                    disabled={inProcess}
                  >
                    {t("plan_management.discard_changes")}
                  </Button>
                  <Button
                    type="primary"
                    onClick={activatePlan}
                    className="plan-page-header-left-btn"
                    loading={inProcess}
                  >
                    {plan.status === "active" || plan.status === "pending"
                      ? t("plan_management.save")
                      : t("plan_management.activate")}
                  </Button>
                  <DiscardModalComponent
                    isModalOpen={isOpenDiscardModal}
                    handleOk={handleDiscardOk}
                    handleCancel={handleDiscardCancel}
                    title={"Discard changes"}
                  />
                </>
              ) : null}
            </div>
          </div>
          <div className="plan-info">
            <div className="plan-info-item-container">
              <p className="plan-info-item-title">
                {t("plan_management.table.cohort")}
              </p>
              <TagsSelect
                defaultValue={[
                  ...cohorts,
                  ...normalizeGroups(plan.activeGroups),
                ].filter((cohort) => planActiveCohortsIds?.includes(cohort.id))}
                options={[...cohorts, ...normalizeGroups(plan.activeGroups)]}
                onChange={updatePlanCohorts}
                selectPlaceholder="Select cohort"
              />
            </div>
            <div className="plan-info-item-container">
              <p className="plan-info-item-title">
                {t("plan_management.status")}
              </p>
              <UserActivityStatus userStatus={plan.status} fontSize={14} />
            </div>
          </div>
          <div>
            <Card title={t("plan_management.weekly_plan")}>
              <PlanCalendar
                planId={plan.id}
                planTasks={planTasks}
                addTaskToPlan={addTaskToPlan}
                updateTaskPlan={updateTaskPlan}
                planScenarios={planScenarios}
                scenariosList={scenarios}
                addScenarioToPlan={addScenarioToPlan}
                updateScenariosPlan={updateScenariosPlan}
                refreshPlan={refreshPlan}
              />
            </Card>
          </div>
        </>
      )}
    </div>
  );
};

export default PlanPage;
