import axios from "axios";

const getPlans = () => axios.get("api/v1/plan-managements");

const createPlan = () => axios.post("api/v1/plan-managements");

const getPlanById = (id) => axios.get(`api/v1/plan-managements/${id}`);

const updateWeeklySchedule = (planId, task) => {
  console.log(planId, task);
  return axios.put(
    `api/v1/plan-managements/${planId}/upsertWeeklyScheduleActivity`,
    {
      ...task,
      dailyTaskId: task.DailyTask?.id ?? null,
      scenarioId: task.scenarioId,
    }
  );
};
const updatePlan = (id, field) =>
  axios.put(`api/v1/plan-managements/${id}`, field);

const updatePlanCohort = (weeklyScheduleId, groupScheduleIds) =>
  axios.put(
    `api/v1/plan-managements/weeklySchedule/${weeklyScheduleId}/update`,
    groupScheduleIds
  );

const deletePlan = (id) => axios.delete(`api/v1/plan-managements/${id}`);

const deleteActivity = (planId, activityId) =>
  axios.delete(
    `api/v1/plan-managements/${planId}/deleteWeeklyScheduleActivity/${activityId}`
  );

const activatePlan = (weeklyScheduleId) =>
  axios.put(
    `api/v1/plan-managements/weeklySchedule/${weeklyScheduleId}/activate`
  );

const discardPlan = (weeklyScheduleId) =>
  axios.put(
    `api/v1/plan-managements/weeklySchedule/${weeklyScheduleId}/discard`
  );

const duplicatePlan = (id) =>
  axios.post(`api/v1/plan-managements/${id}/duplicate`);

const ApiPlanManagement = {
  getPlans,
  getPlanById,
  createPlan,
  updateWeeklySchedule,
  updatePlan,
  deletePlan,
  deleteActivity,
  activatePlan,
  discardPlan,
  updatePlanCohort,
  duplicatePlan,
};

export default ApiPlanManagement;
