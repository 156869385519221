import "./styles.css";
import UsersTrailTable from "./components/users-table/users-table";
import SubjectsTrailTable from "./components/subjects-table/subjects-table";
import ScenariosTrailTable from "./components/scenarios-table/scenarios-table";
import { useLocation } from "react-router-dom";
import { memo, useMemo } from "react";
import { MainLayoutV2 } from "../../components/main-layout-v2/main-layout-v2";
import { MainNavigation } from "../../components/main-navigation/main-navigation";
import { Sidebar } from "../../components/sidebar/sidebar";

const AuditTrail = memo(() => {
  const { hash } = useLocation();

  const leftSideNavItems = [
    { key: "/audit-trail#users", label: "Users", default: true },
    { key: "/audit-trail#subjects", label: "Subjects" },
    { key: "/audit-trail#scenarios", label: "Scenarios" }
  ];

  const content = useMemo(() => {
    switch (hash) {
      default:
      case "#users":
        return <UsersTrailTable />;
      case "#subjects":
        return <SubjectsTrailTable />;
      case "#scenarios":
        return <ScenariosTrailTable />;
    }
  }, [hash]);

  return (
    <MainLayoutV2>
      <MainNavigation />
      <Sidebar
        title={"Audit trail"}
        items={leftSideNavItems}
        path={"/audit-trail"}
      />
      {content}
    </MainLayoutV2>
  );
});

export default AuditTrail;
