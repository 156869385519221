import "./styles.css";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import useSearch from "../administration/user-management/useSearch";
import useSubjects from "./useSubjects";
import InviteSubjectModal from "../../components/modals/invitations/subject/invite-subject";
import SubjectDashboard from "./dashboard/subject-dashboard";
import SubjectsList from "./subjects-list/subjects-list";
import { MainNavigation } from "../../components/main-navigation/main-navigation";
import { Sidebar } from "../../components/sidebar/sidebar";
import { MainLayoutV2 } from "../../components/main-layout-v2/main-layout-v2";
import { Body } from "../../components/main-body/body";

const SubjectManagement = () => {
  const { t } = useTranslation();
  const { hash } = useLocation();
  const navigate = useNavigate();

  const { search, handleUpdateSearchValue } = useSearch();
  const { subjects, hasMore, isLoading, fetchSubjects } = useSubjects(search);

  const [isOpen, setOpen] = useState(false);

  const handleToogleModal = () => {
    if (isOpen) {
      fetchSubjects();
    }
    setOpen(!isOpen);
  };

  const headerTitle = useMemo(() => {
    switch (hash) {
      default:
      case "#subjects":
        return "Subjects";
      case "#pending":
        return "Pending subjects";
      case "#disabled":
        return "Blocked subjects";
    }
  }, [hash, t]);

  const fetchEnrollInviteUserTemplate = () =>
    axios.get("api/v1/enrollment-fields/user");

  const leftSideNavItems = [
    { key: "/subject-management#subjects", label: "Subjects", default: true },
    { key: "/subject-management#pending", label: "Pending subjects" },
    { key: "/subject-management#disabled", label: "Blocked subjects" },
  ];

  const headerProps = useMemo(
    () => ({
      title: headerTitle,
      search: { value: search, onHandleChange: handleUpdateSearchValue },
      create: {
        label: t("subject_management.btn.add"),
        onClick: handleToogleModal,
        renderModalComponent: (
          <InviteSubjectModal
            isOpen={isOpen}
            close={handleToogleModal}
            fetchEnrollInviteUserTemplate={fetchEnrollInviteUserTemplate}
          />
        ),
      },
    }),
    [headerTitle, search, isOpen, t]
  );

  const header = useMemo(() => {
    switch (hash) {
      case "#dashboard":
        return {};
      default:
        return headerProps;
    }
  }, [headerTitle, search, isOpen, hash, headerProps]);

  const openSubject = (subject) => {
    navigate(`/subject-management/subject/${subject.id}`);
  };

  const content = useMemo(() => {
    switch (hash) {
      case "#dashboard":
        return <SubjectDashboard />;
      default:
        return (
          <SubjectsList
            subjects={subjects}
            hasMore={hasMore}
            isLoading={isLoading}
            fetchSubjects={fetchSubjects}
            onHandleRowClick={openSubject}
          />
        );
    }
  }, [hash, subjects, hasMore, isLoading, fetchSubjects, openSubject]);

  return (
    <MainLayoutV2>
      <MainNavigation />
      <Sidebar items={leftSideNavItems} title={t("subject_management.title")} />
      <Body header={header}>{content}</Body>
    </MainLayoutV2>
  );
};

export default SubjectManagement;
