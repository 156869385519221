import { memo, useMemo } from "react";
import { Button, Dropdown } from "antd";
import { Icon } from "../../../../components/icon/icon";

export const CategorySelector = memo(({ categories, control, editMode }) => {
  const label = useMemo(() => {
    return categories.find((c) => c.id === control.category.value)?.name;
  }, [control.category.value, categories]);
  if (!editMode && !label) {
    return null;
  }
  return (
    <Dropdown
      trigger="click"
      menu={{
        items: categories.map((c) => ({
          key: c.id,
          label: c.name,
        })),
        onClick: (e) => control.category.change(e.key),
      }}
    >
      <Button
        style={{
          pointerEvents: editMode ? "all" : "none",
          color: label ? undefined : "var(--text-2)",
          marginBottom: 16,
        }}
      >
        {label || "Select category..."}
        {editMode && (
          <Icon
            name={"arrow-down"}
            color={"text-2"}
            style={{ marginLeft: 4 }}
            size={12}
          />
        )}
      </Button>
    </Dropdown>
  );
});
