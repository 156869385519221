import { Input, InputNumber, Select } from "antd";
import { useEffect, useState } from "react";

const FormInput = ({ responseId, text, onChange }) => {
  return (
    <Input
      bordered={false}
      defaultValue={text}
      onChange={({ target }) => onChange(responseId, target.value)}
      maxLength={1000}
    />
  );
};

const FormRange = ({ responseId, range, currentValue, onChange }) => {
  const [values, setValues] = useState();

  const text = currentValue
    .split(" ")
    .filter((t) => typeof +t === "number" && !isNaN(+t));

  const updateValues = (value, unit) => {
    const splitedCurrentValue = currentValue.split(" ");
    const units = splitedCurrentValue.filter((v) => isNaN(+v));

    const splitedValues = values?.split(" ") || [];

    if (splitedCurrentValue.length < 3) {
      setValues(`${value} ${unit}`);
    } else {
      const index = units.indexOf(unit);

      const firstPart =
        index === 0
          ? `${value} ${unit}`
          : `${splitedValues[0] || splitedCurrentValue[2]} ${
              splitedValues[1] || splitedCurrentValue[3]
            }`;
      const secondPart =
        index === 1
          ? `${value} ${unit}`
          : `${splitedValues[2] || splitedCurrentValue[0]} ${
              splitedValues[3] || splitedCurrentValue[1]
            }`;

      setValues(`${firstPart} ${secondPart}`);
    }
  };

  useEffect(() => {
    if (values) {
      onChange(responseId, values);
    }
  }, [values]);

  const renderInputs = range.map((value, index) => {
    const { from, to, unit } = value;
    return (
      <>
        <div className="form-input-number">
          <InputNumber
            min={from}
            max={to}
            bordered={true}
            defaultValue={+text[index] || +currentValue}
            onChange={(value) => updateValues(value, unit)}
          />
        </div>
        <span>{unit}</span>
      </>
    );
  });

  return <div className="form-input-number-container">{renderInputs}</div>;
};

const FormSelect = ({ responseId, select, currentValue, onChange }) => {
  const { values } = select;

  const options = values.map((value) => ({ id: value, value }));

  const defaultValue = { id: currentValue, value: currentValue };

  return (
    <Select
      bordered={false}
      options={options}
      defaultValue={defaultValue || options[0]}
      onChange={(value) => onChange(responseId, value)}
    />
  );
};

const editFields = (field = {}, onChange) => {
  const { pickers, text, id } = field;

  if (!pickers?.length && text) {
    return <FormInput responseId={id} text={text} onChange={onChange} />;
  }

  if (
    pickers?.filter(
      (p) => p.type === "RANGE" && p.from && p.to && typeof p.from === "number"
    ).length
  ) {
    return (
      <FormRange
        responseId={id}
        range={pickers}
        onChange={onChange}
        currentValue={text}
      />
    );
  }

  if (pickers?.length === 1 && pickers[0]?.values.length) {
    return (
      <FormSelect
        responseId={id}
        select={pickers[0]}
        currentValue={text}
        onChange={onChange}
      />
    );
  }

  return null;
};
export default editFields;
