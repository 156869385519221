import { memo } from "react";
import "./chips.css";
import { Icon } from "../icon/icon";

const colors = {
  yellow: { borderColor: "var(--yellow)", color: "var(--yellow)" },
  blue: { borderColor: "var(--blue)", color: "var(--blue)" },
  red: { borderColor: "var(--red)", color: "var(--red)" },
  green: { borderColor: "var(--green)", color: "var(--green)" },
  violet: { borderColor: "var(--violet)", color: "var(--violet)" },
  grey: { borderColor: "var(--border-color)", color: "var(--text-2)" },
};

const getColors = (color) => {
  const colorKeys = Object.keys(colors);
  if (colorKeys.includes(color)) {
    return colors[color];
  }
  return { borderColor: color, color: color };
};

export const Chips = memo(
  ({
    id,
    text,
    color,
    isSolid,
    fontSize,
    backgroundColor,
    borderColor,
    iconName,
    iconColor,
    cursor,
    style
  }) => {
    return (
      <div
        id={id}
        className={"lf-chips"}
        style={{
          ...getColors(color || "grey"),
          borderColor:
            backgroundColor ?? getColors(color || "grey").borderColor,
          fontSize,
          backgroundColor,
          cursor: cursor ? "pointer" : "default",
          ...style
        }}
      >
        <span style={iconName ? { marginRight: 5, flex:1 } : {}}>{text}</span>
        {iconName && <Icon name={iconName} color={iconColor || color} />}
      </div>
    );
  }
);
