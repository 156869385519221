import moment from "moment";
import { Button, Drawer } from "antd";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import crossIcon from "../../../../assets/cross.svg";
import editIcon from "../../../../assets/edit.svg";
import editFields from "./generateEditFields";
import ApiSubjectsManagement from "../../../../api/subjects-manager";
import useNotification from "../../../../components/notification/notification";

const SurveyDrawer = ({ isOpenDrawer, close, drawerData }) => {
  const { t } = useTranslation();
  const [isEdit, setEdit] = useState(false);
  const { openNotification } = useNotification();

  const [changedFields, setChangedFields] = useState([]);

  const onChange = (responseId, text) => {
    const hasId = changedFields.filter((c) => c.responseId === responseId)[0];
    if (hasId) {
      setChangedFields(
        changedFields.map((field) =>
          field.responseId === responseId ? { ...field, text } : field
        )
      );
      return;
    }

    setChangedFields([...changedFields, { responseId, text }]);
  };

  const updateSurvey = () => {
    const updatedResponse = drawerData.response.reduce((acc, item) => {
      const changedItem = changedFields.filter(
        (field) => field.responseId === item.id
      )[0];

      if (changedItem) {
        const { text } = changedItem;
        acc.push({ ...item, text });
      } else {
        acc.push(item);
      }

      return acc;
    }, []);

    ApiSubjectsManagement.updateSurvey(drawerData.id, updatedResponse)
      .then(() => {
        openNotification({
          type: "success",
          message: <p>{t("subject_management.notification.updated")}</p>,
        });
      })
      .catch((e) => {
        openNotification({
          type: "error",
          message: (
            <p>
              {t(
                `subject_management.notification.${
                  e?.response?.data?.error?.code || "error"
                }`
              )}
            </p>
          ),
        });
      })
      .finally(() => {
        setChangedFields([]);
      });
  };

  const surveyAnswers = useMemo(() => {
    if (!drawerData || isEdit) return null;

    return drawerData.response.map((r) => {
      return (
        <div key={r.id} className="survey__drawer__form-item">
          <p className="survey__drawer__form-item-label">
            {r.question || "N/A"}
          </p>
          <p>{`${r.answer}`.replace("[object Object]", "")}</p>
        </div>
      );
    });
  }, [drawerData, isEdit]);

  const surveyAnswersEdit = useMemo(() => {
    if (!drawerData || !isEdit) return null;

    const questions = [];
    const answers = [];

    drawerData.response.forEach((r) => {
      const { id, questionText, text, pickers } = r;

      questions.push({ id, questionText });

      if (pickers?.length) {
        answers.push({ id, pickers, text });
        return;
      }

      answers.push({ id, text });
    });

    const normalizeAnswers = answers.map((answer) =>
      editFields(answer, onChange)
    );

    return questions.map(({ id, questionText }, index) => {
      return (
        <div key={id} className="survey__drawer__form-item">
          <p className="survey__drawer__form-item-label">
            {questionText || "N/A"}
          </p>
          {/* <p>{r.text.replace("[object Object]", "")}</p> */}
          <p>{normalizeAnswers[index]}</p>
        </div>
      );
    });
  }, [drawerData, isEdit, changedFields]);

  const toogleEdit = () => {
    setEdit(!isEdit);
  };

  const headerRightButtons = useMemo(() => {
    if (isEdit) {
      return (
        <>
          <Button
            style={{
              height: 40,
              background: "rgba(108, 108, 137, 0.08)",
            }}
            onClick={toogleEdit}
          >
            {t("profile.btns.cancel")}
          </Button>
          <Button
            type="primary"
            style={{ marginLeft: 8, height: 40 }}
            onClick={updateSurvey}
          >
            {t("profile.btns.save")}
          </Button>
        </>
      );
    }

    return (
      <>
        <img
          src={editIcon}
          alt="edit"
          role="presentation"
          onClick={toogleEdit}
          style={{ width: 38, marginTop: 2 }}
        />
        <img src={crossIcon} alt="close" role="presentation" onClick={close} />
      </>
    );
  }, [isEdit, changedFields, drawerData]);

  return (
    <Drawer open={isOpenDrawer} width={600} closable={false} onClose={close}>
      <div className="surveys__drawer__header">
        <div className="surveys__drawer__header__left">
          <p>{drawerData?.OnboardingSurvey.title}</p>
          <p>{`${t("subject_management.table.completion_date")}: ${moment(
            drawerData?.updatedAt
          )
            .utc()
            .format("DD MMM YYYY, HH:mm")}, UTC`}</p>
        </div>
        <div className="surveys__drawer__header__right">
          {headerRightButtons}
        </div>
      </div>

      <div className="profile_form-container surveys__drawer__form-container">
        {isEdit ? surveyAnswersEdit : surveyAnswers}
      </div>
    </Drawer>
  );
};

export default SurveyDrawer;
