import sleepDuration from "../../../../../assets/health/sleep-duration.svg";
import dailyStepCount from "../../../../../assets/health/steps.svg";
import activeEnergy from "../../../../../assets/health/active-energy.svg";
import restingHeartRate from "../../../../../assets/health/resting-heart-rate.svg";

const healthIcons = {
  sleepDuration,
  dailyStepCount,
  restingHeartRate,
  activeEnergy,
};

export default healthIcons;
