import classNames from "classnames";
import { memo, useLayoutEffect, useRef, useState } from "react";
import { hasData } from "../../_utils/has-data";

export const TableCell = memo(
  ({ title, text, img, imgStyle, markColor, subtext }) => {
    const textBlock = useRef(null);
    const [markHeight, setMarkHeight] = useState(0);

    useLayoutEffect(() =>
      setMarkHeight(
        textBlock.current.offsetHeight > 36
          ? textBlock.current.offsetHeight
          : 36
      )
    );

    return (
      <div
        ref={textBlock}
        className={classNames("lf-table-cell", {
          "no-data": !(hasData(text) || hasData(title)),
        })}
      >
        {markColor && (
          <div
            style={{
              backgroundColor: markColor,
              width: 9,
              height: markHeight,
              marginRight: 10,
              borderRadius: 16,
              flexShrink: 0,
            }}
          />
        )}
        {img && typeof img === "string" && (
          <div
            className={classNames("lf-table-cell__img")}
            style={{ backgroundImage: `url(${img})`, ...imgStyle }}
          />
        )}
        <div>
          {title && (
            <span className={classNames("lf-table-cell__title")}>{title}</span>
          )}
          {text && (
            <span className={classNames("lf-table-cell__text")}>{text}</span>
          )}
          {subtext && (
            <span className={classNames("lf-table-cell__subtext")}>
              {subtext}
            </span>
          )}
        </div>
      </div>
    );
  }
);
