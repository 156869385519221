import { memo } from "react";

const IconSetting = memo(() => {
  return (
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd"
                d="M2.25 7.5C2.25 4.60051 4.60051 2.25 7.5 2.25C10.3995 2.25 12.75 4.60051 12.75 7.5C12.75 8.93785 12.172 10.2407 11.2356 11.1888C11.2276 11.1962 11.2197 11.2038 11.2119 11.2116C11.204 11.2194 11.1964 11.2274 11.1891 11.2354C10.2409 12.1719 8.93795 12.75 7.5 12.75C4.60051 12.75 2.25 10.3995 2.25 7.5ZM11.7134 12.7738C10.5586 13.6976 9.09382 14.25 7.5 14.25C3.77208 14.25 0.75 11.2279 0.75 7.5C0.75 3.77208 3.77208 0.75 7.5 0.75C11.2279 0.75 14.25 3.77208 14.25 7.5C14.25 9.0937 13.6977 10.5584 12.774 11.7131L16.0305 14.9696C16.3234 15.2625 16.3234 15.7374 16.0305 16.0303C15.7376 16.3232 15.2628 16.3232 14.9699 16.0303L11.7134 12.7738Z"
                fill="#151515"/>
      </svg>

  );
});

export default IconSetting;
