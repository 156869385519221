import axios from "axios";
import toQueryString from "../common/utils/to-query-string";

const invite = (newUser) => axios.post("api/v1/auth/signup/user", newUser);

const getUsers = (query) => axios.get(toQueryString("api/v1/users?", query));

const resendInvite = (userId) => axios.post(`/api/v1/users/${userId}/invite`);

const deleteUser = (id, newContentAuthor) =>
  axios.post(`/api/v1/users/${id}/delete`, { userId: newContentAuthor });

const ApiUsersManagement = {
  invite,
  getUsers,
  resendInvite,
  deleteUser,
};

export default ApiUsersManagement;
