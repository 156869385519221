import { useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import {
  DeleteOutlined,
  EllipsisOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { Dropdown, Modal } from "antd";
import UserActivityStatus from "../../../../components/user-activity-status/user-activity-status";
import InfinityTable from "../../../../components/infinity-table/infinity-table";

const DropdownActionItem = ({ remove, duplicate }) => {
  const items = [
    {
      key: "1",
      label: "Duplicate",
      icon: <CopyOutlined />,
      onClick: () => duplicate(),
    },
    {
      key: "2",
      label: "Delete",
      icon: <DeleteOutlined />,
      onClick: () => remove(),
    },
  ];

  return (
    <Dropdown
      menu={{
        items,
        onClick: (e) => {
          e.domEvent.stopPropagation();
        },
      }}
      trigger={["click"]}
      overlayStyle={{ width: 120 }}
    >
      <EllipsisOutlined
        style={{ cursor: "pointer", width: 30 }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </Dropdown>
  );
};

const DeleteModalComponent = ({
  scenario,
  handleOk,
  handleCancel,
  isModalOpen,
}) => {
  return (
    <Modal
      title={scenario?.title}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ danger: true }}
      className="plan-page-action-modal"
    >
      <p>Are you sure you want to delete scenario?</p>
    </Modal>
  );
};

const ScenarioTable = ({
  data,
  onHandleRowClick,
  deleteScenario,
  isOnboarding,
  handleDuplicate,
  loading,
}) => {
  const [isModalOpen, setModalOpen] = useState();
  const [item, setItem] = useState();

  const { t } = useTranslation();
  const columns = [
    {
      title: t("administration.scenario.table.name"),
      dataIndex: "title",
      render: (_, scenario) => <span>{scenario.title || "N/A"}</span>,
      key: "title",
      width: "30%",
    },
    isOnboarding
      ? {
          title: t("administration.scenario.table.cohort"),
          dataIndex: "cohort",
          render: (_, scenario) => (
            <span>
              {scenario.GroupOnboardingSurveys.reduce(
                (acc, cohort) =>
                  `${acc}${acc.length ? "," : ""} ${
                    cohort.EnrollmentFieldOption.value
                  }`,
                ""
              ) || "N/A"}
            </span>
          ),
          key: "cohort",
          width: "30%",
        }
      : {},
    {
      title: t("plan_management.table.status"),
      dataIndex: "status",
      render: (_, scenario) =>
        scenario.status ? (
          <UserActivityStatus userStatus={scenario.status} />
        ) : (
          <span>"N/A"</span>
        ),
      key: "status",
      width: "30%",
    },
    {
      title: t("administration.scenario.table.last_modified"),
      dataIndex: "updatedAt",
      render: (_, scenario) => (
        <span>
          {`${moment(scenario.updatedAt)
            .utc()
            .format("DD MMM YYYY, HH:mm")}, UTC` || "N/A"}
        </span>
      ),
      key: "updatedAt",
      width: "30%",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, plan) => (
        <DropdownActionItem
          planId={plan.id}
          remove={() => handleOpenDeleteModal(plan)}
          duplicate={() => handleDuplicate(plan)}
        />
      ),
      key: "action",
      width: "10%",
    },
  ].filter((c) => c.title);

  const handleOpenDeleteModal = (plan) => {
    setModalOpen(true);
    setItem(plan);
  };

  const handleCloseDeleteModal = () => {
    setModalOpen(false);
    setItem();
  };

  const handleOkPress = (id, name) => {
    handleCloseDeleteModal();
    deleteScenario(id, name);
  };

  return (
    <>
      <DeleteModalComponent
        scenario={item}
        handleOk={() => handleOkPress(item.id, item.name)}
        handleCancel={handleCloseDeleteModal}
        isModalOpen={isModalOpen}
      />
      <InfinityTable
        data={data}
        tableColumns={columns}
        onHandleRowClick={onHandleRowClick}
        isLoading={loading}
      />
    </>
  );
};

export default ScenarioTable;
