import encodeSvgWithColor from "../../../common/utils/encodeSvgWithColor";

const Task = ({ data }) => {
  const { name, title, description, backgroundColor, avatarColor, avatar } =
    data;
  return (
    <div className="simulator-task-container">
      <div
        className="icon-wrapper"
        style={{
          backgroundColor,
        }}
      >
        {avatar && (
          <img src={encodeSvgWithColor(avatar, avatarColor)} alt="avatar" />
        )}
      </div>
      <p className="simulator-task-title">{name || title}</p>
      <p className="simulator-task-description">{description}</p>
    </div>
  );
};

export default Task;
