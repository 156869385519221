import { memo } from "react";

const Locked = memo((color) => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3332 4.66634H10.7498V3.49967C10.7498 1.88967 9.44317 0.583008 7.83317 0.583008C6.22317 0.583008 4.9165 1.88967 4.9165 3.49967V4.66634H4.33317C3.6915 4.66634 3.1665 5.19134 3.1665 5.83301V11.6663C3.1665 12.308 3.6915 12.833 4.33317 12.833H11.3332C11.9748 12.833 12.4998 12.308 12.4998 11.6663V5.83301C12.4998 5.19134 11.9748 4.66634 11.3332 4.66634ZM7.83317 9.91634C7.1915 9.91634 6.6665 9.39134 6.6665 8.74967C6.6665 8.10801 7.1915 7.58301 7.83317 7.58301C8.47484 7.58301 8.99984 8.10801 8.99984 8.74967C8.99984 9.39134 8.47484 9.91634 7.83317 9.91634ZM9.6415 4.66634H6.02484V3.49967C6.02484 2.50217 6.83567 1.69134 7.83317 1.69134C8.83067 1.69134 9.6415 2.50217 9.6415 3.49967V4.66634Z"
        fill={color}
      />
    </svg>
  );
});

export default Locked;
