import { useState } from 'react';
import { Editor } from "@hydra-editor/scenario-designer";
import "reactflow/dist/style.css";
import "@hydra-editor/scenario-designer/style";
import ScenarioSimulator from "../../../../components/scenario-simulator/scenario-simulator";
import useScenarioConfig from "./useScenarioConfig";

const ScenarioEditorWrapper = ({
  id,
  scenario,
  showSimulator,
  update,
  tasks = [],
  fetchers: {
    getAlternativeMessages,
    getAllMessages,
    getOptionsByMessage,
    getAllFitFacts,
    getAllArticles,
    getFilteredFitFacts,
    getFilteredArticles,
    getAllScenario,
  },
}) => {
  const { nodesConfig, nodesDefinitions } = useScenarioConfig(tasks);

  const [updatedScenario, setUpdatedScenario] = useState(scenario);

  const changeScenario = (changedScenario, validation) => {
    const serializedChangedScenario = JSON.stringify(changedScenario);
    const serializedUpdatedScenario = JSON.stringify(updatedScenario);
    if (serializedUpdatedScenario !== serializedChangedScenario) {
      update?.(changedScenario, validation);
      setUpdatedScenario(changedScenario);
    }
  }

  if (!nodesConfig || !nodesDefinitions) {
    return null;
  }

  return (
    <div id="scenario-editor-wrapper">
      <Editor
        id={id}
        nodesConfig={nodesConfig}
        nodesDefinition={nodesDefinitions}
        // INFO: use here real scenario data (if undefined - empty scenario is used)
        defaultValue={updatedScenario ?? undefined}
        onChange={changeScenario}
        fetchers={{
          fetchAlternatives: getAlternativeMessages,
          fetchAllMessages: getAllMessages,
          fetchOptionsByMessage: getOptionsByMessage,
          fetchFitFacts: getAllFitFacts,
          fetchArticles: getAllArticles,
          fetchFilteredFitFacts: getFilteredFitFacts,
          fetchFilteredArticles: getFilteredArticles,
          fetchAllScenario: getAllScenario,
        }}
      />
      {showSimulator && (
        <div className="scenario-editor-simulator-container">
          <ScenarioSimulator
            scenarioVersionId={id}
            scenario={scenario}
            tasks={tasks}
          />
        </div>
      )}
    </div>
  );
};

export default ScenarioEditorWrapper;
