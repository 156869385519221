const noDataValues = [
  "-",
  "–",
  "—",
  "na",
  "n/a",
  "none",
  "null",
  "undefined",
  "",
  0,
  "0",
  undefined,
];
export const hasData = (data) => {
  return !noDataValues.includes(data);
};
