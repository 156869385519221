import { useEffect, useState } from "react";
import { ColorPicker } from "../icons/icons";

const SimpleColorPicker = ({ onChange, defaultValue, readonly, opened }) => {
  const [isOpenColorPicker, setOpenColorPicker] = useState();
  const [color, setColor] = useState(defaultValue);

  useEffect(() => {
    if (readonly) {
      setOpenColorPicker(false);
    }
  }, [readonly]);

  useEffect(() => {
    setOpenColorPicker(opened);
  }, [opened]);

  const handleSelectColor = (color) => {
    setColor(color);
    // setOpenColorPicker(false);
    onChange(color);
  };

  return (
    <div>
      {isOpenColorPicker ? (
        <div>
          <ColorPicker
            onSelect={handleSelectColor}
            activeColor={color?.avatarColor}
          />
        </div>
      ) : (
        <div style={{ padding: "12px 8px" }}>
          <div
            style={{
              width: 24,
              height: 24,
              borderRadius: 24,
              backgroundColor: color?.backgroundColor || color || "#28282866",
            }}
            role="presentation"
            onClick={() => !readonly && setOpenColorPicker(true)}
          />
        </div>
      )}
    </div>
  );
};

export default SimpleColorPicker;
