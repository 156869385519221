import encodeSvgWithColor from "../../../common/utils/encodeSvgWithColor";

const SubjectProgressCard = ({ tasks }) => {
  return (
    <div className="progress-card__section">
      {tasks.map((task, index) => {
        const { percent, totalAdherence, totalDoneAdherence } = task;
        const { title, avatar, avatarColor, backgroundColor } = task.dailyTask;

        return (
          <div className="progress-card__container" key={index}>
            <div className="progress-card__title">
              <div
                className="icon-wrapper"
                style={{
                  width: 48,
                  height: 48,
                  backgroundColor,
                  marginBottom: 10,
                }}
              >
                <img
                  src={encodeSvgWithColor(avatar, avatarColor)}
                  alt="avatar"
                />
              </div>
              <p>{title}</p>
            </div>
            <div className="progress-card__results">
              <span style={{ fontSize: 32 }}>{Math.floor(percent * 100)}</span>
              <span>%</span>
              <div style={{ fontSize: 14, marginTop: 8, fontWeight: 700 }}>
                <span style={{ fontWeight: 700 }}>{totalDoneAdherence}</span>
                <span>{`/${totalAdherence} Tasks`}</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SubjectProgressCard;
