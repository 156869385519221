import classNames from "classnames";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export const TableCellPicture = ({ pageId, proportion = 100 }) => {
  return (
    <div className={classNames("lf-table-cell-picture", {})}>
      <div className={classNames("lf-table-cell-picture__inside")}>
        <LazyLoadImage
          className={classNames("lf-table-cell-picture__img")}
          src={`${
            process.env.REACT_APP_DEV_URL || document.location.origin
          }/api/v1/pages/${pageId}/icon-mini`}
          effect="blur"
          loading="lazy"
          width={150}
        />
        {/* <div
          className={classNames("lf-table-cell-picture__img")}
          style={{ backgroundImage: `url(${img})` }}
        /> */}
      </div>
    </div>
  );
};
