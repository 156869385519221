import { useEffect, useState } from "react";

const useScenarioSimulatorController = (
  scenario,
  embeddedScenarios,
  isReady
) => {
  const [currentScenario, setCurrentScenario] = useState(scenario);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [userAnswers, setUserAnswers] = useState([]);
  const [history, setHistory] = useState([]);
  const [inProcess, setInProcess] = useState(false);

  const setCurrentQuestionAfterNormalizeQuestionText = (question) => {
    const joinedMessages = question.data?.message_alternatives?.length
      ? [question.data.message, ...question.data.message_alternatives]
      : null;

    const randomIndex = Math.floor(Math.random() * joinedMessages?.length);

    const normalizeMessage = {
      message: joinedMessages?.[randomIndex] || question.data?.message,
    };

    const normalizeQuestion = {
      ...question,
      data: { ...question.data, ...normalizeMessage },
    };

    setCurrentQuestion(normalizeQuestion);
  };

  function addNextNodesToFinishNode(obj, nextNodes) {
    if (!obj || !nextNodes) return {};

    for (const key in obj) {
      if (obj[key].type === "FinishNode") {
        obj[key].type = "GenericNode";
        obj[key].nextNodes = nextNodes;
      }
    }
  }

  const getFirstQuestionOfScenario = (scenario) => {
    const startNode = Object.values(scenario).find(
      (node) => node.type === "StartNode"
    );
    const firstQuestion = scenario[startNode.nextNodes[0]];
    setCurrentQuestionAfterNormalizeQuestionText(firstQuestion);
  };

  const toNextQuestion = (nextQuestionId) => {
    setInProcess(true);
    let processWithDelay;

    clearTimeout(processWithDelay);

    processWithDelay = setTimeout(() => {
      setHistory([...history, currentQuestion]);
      setCurrentQuestionAfterNormalizeQuestionText(
        currentScenario[nextQuestionId]
      );
      setInProcess(false);
    }, Math.floor(Math.random() * (3000 - 1500) + 1500));
  };

  const rollBack = (id, answer) => {
    setInProcess(false);

    const selectedHistoryIndex = history.findIndex((i) => i.id === id);
    const newHistory = [...history].slice(0, selectedHistoryIndex);
    const newUserAnswers = [...userAnswers].slice(0, selectedHistoryIndex);

    setHistory(newHistory);
    setUserAnswers(newUserAnswers, { id, ...answer });
    setCurrentQuestionAfterNormalizeQuestionText(currentScenario[id]);
  };

  const processConditionNode = (answer, nextNode) => {
    const normalizedConditionals = nextNode.data.condStatements.map(
      (state) => state.value?.map((v) => v.value) ?? null
    );

    const findConditionIndex = (v) => {
      if (!v) return false;

      if (v.includes(answer.value)) return true;

      if (
        v?.includes(
          userAnswers.find((a) => a.id === nextNode.data.condSourceId)?.id
        ) ||
        v?.includes(
          userAnswers.find((a) => a.id === nextNode.data.condSourceId)?.value
        ) ||
        v?.filter((value) =>
          userAnswers
            .find((a) => a.id === nextNode.data.condSourceId)
            ?.answers?.map((a) => a.value)
            .includes(value)
        ).length
      )
        return true;

      return v?.find(
        (v) => answer?.answers?.filter((a) => v === a.value).length
      );
    };

    let nextNodeIndex = normalizedConditionals.findIndex(findConditionIndex);

    if (nextNodeIndex === -1) {
      nextNodeIndex = normalizedConditionals.findIndex((v) => !v);
    }

    const jumpToAnotherBranch =
      currentScenario[nextNode.nextNodes[nextNodeIndex]];

    toNextQuestion(jumpToAnotherBranch.nextNodes[0]);
    return;
  };

  const setAnswer = (id, answer) => {
    if (inProcess) return;

    const nextQuestionId = currentScenario[id].nextNodes[0];
    const nextNode = currentScenario[nextQuestionId];

    if (!nextNode) return;

    const isPreviosAnswer = userAnswers.find((answer) => answer.id === id);
    const answersWithLabels = userAnswers.filter((answer) => answer.label);
    const previosAnswer = answersWithLabels[answersWithLabels.length - 1];

    // if(nextNode.type === "IfBranchNode") {

    // }

    if (nextNode.data?.nodeType === "scenario:picker") {
      console.log("NEXT NODE", nextNode);
      const embeddedScenario = embeddedScenarios[nextNode.id];

      if (embeddedScenario) {
        const embeddedScenarioData = JSON.parse(embeddedScenario.data);
        setCurrentScenario({
          ...scenario,
          ...addNextNodesToFinishNode(embeddedScenarioData, nextNode.nextNodes),
        });
        getFirstQuestionOfScenario(embeddedScenarioData);
        return;
      }
    }

    if (nextNode.type === "GenericNode" && !isPreviosAnswer) {
      setUserAnswers([...userAnswers, { id, ...answer }]);
      toNextQuestion(nextQuestionId);

      return;
    }

    if (!isPreviosAnswer && nextNode.type === "IfConditionNode") {
      if (answer) {
        setUserAnswers([...userAnswers, { id, ...answer }]);
      }
      processConditionNode(answer ?? previosAnswer, nextNode);
      return;
    }

    if (nextNode.type === "IfCloseNode") {
      if (isPreviosAnswer) {
        rollBack(id, answer);
        return;
      }
      setUserAnswers([...userAnswers, { id, ...answer }]);
      toNextQuestion(nextNode.nextNodes[0]);
      return;
    }

    if (isPreviosAnswer) {
      rollBack(id, answer);
    } else {
      toNextQuestion(nextQuestionId);
      setUserAnswers([...userAnswers, { id, ...answer }]);
    }
  };

  useEffect(() => {
    if (
      currentQuestion.id &&
      !inProcess &&
      ![
        "message:single",
        "message:multi",
        "message:input",
        "message:picker",
        "message:date",
      ].includes(currentQuestion?.data?.nodeType)
    ) {
      setAnswer(currentQuestion.id);
    }
  }, [currentQuestion, inProcess]);

  useEffect(() => {
    if (isReady && !currentQuestion.id && !history.length) {
      getFirstQuestionOfScenario(currentScenario);
    }
  }, [isReady]);

  return {
    currentQuestion,
    userAnswers,
    history,
    setAnswer,
    toNextQuestion,
    inProcess,
  };
};

export default useScenarioSimulatorController;
