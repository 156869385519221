import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ScenarioTable from "./components/scenario-table";
import ScenarioPage from "./components/scenario-page";
import useNotification from "../../../components/notification/notification";
import { Body } from "../../../components/main-body/body";
import "./styles.css";
import ApiScenariosManagement from "../../../api/scenarios-management";

const ScenarioManagement = () => {
  const [scenarios, setScenarios] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [selectedScenario, setSelectedScenario] = useState({});
  const [tasks, setTasks] = useState([]);
  const { t } = useTranslation();
  const { openNotification } = useNotification();
  const [loading, setLoading] = useState();

  const getScenarios = async (deselect) => {
    setLoading(true);
    return ApiScenariosManagement.getScenarios()
      .then((r) => {
        setScenarios(r.data.scenarios);

        if (selectedScenario.id && !deselect) {
          setSelectedScenario(
            r.data.scenarios.find((s) => s.id === selectedScenario.id) ?? {}
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getDailyTasks = async () => {
    return ApiScenariosManagement.getDailyTasks().then((r) => {
      setTasks(r.data.dailyTasks);
    });
  };

  useEffect(() => {
    getScenarios();
    getDailyTasks();
  }, []);

  const createScenario = () => {
    setLoading(true);
    ApiScenariosManagement.createScenario()
      .then((r) => {
        openNotification({
          type: "success",
          message: (
            <p>
              {t("administration.scenario.notification.created", {
                scenario: r.data.scenario.title,
              })}
            </p>
          ),
        });

        setSelectedScenario(r.data.scenario);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteScenario = (id, value) => {
    setLoading(true);

    ApiScenariosManagement.deleteScenario(id)
      .then(() => {
        getScenarios().then(() => {
          openNotification({
            type: "success",
            message: (
              <p>
                {t("administration.scenario.notification.deleted", {
                  scenario: value,
                })}
              </p>
            ),
          });
        });
      })
      .catch(() => {
        openNotification({
          type: "error",
          message: (
            <p>
              {t("administration.scenario.notification.not_implemented", {
                scenario: "",
              })}
            </p>
          ),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDuplicate = (scenario) => {
    setLoading(true);

    ApiScenariosManagement.duplicateScenario(scenario.id)
      .then(() => {
        getScenarios().then(() => {
          openNotification({
            type: "success",
            message: (
              <p>
                {t("administration.scenario.notification.duplicated", {
                  scenario: scenario.title,
                })}
              </p>
            ),
          });
        });
      })
      .catch(() => {
        openNotification({
          type: "error",
          message: (
            <p>
              {t("administration.scenario.notification.not_implemented", {
                scenario: "",
              })}
            </p>
          ),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSelectScenario = (scenario) => {
    setSelectedScenario(scenario);
    setOpen(true);
  };

  const handleDeselectScenario = async () => {
    await getScenarios(true);
    setSelectedScenario({});
  };

  const headerProps = useMemo(
    () => ({
      title: "Scenario Management",
      create: {
        label: t("administration.scenario.create"),
        onClick: createScenario,
      },
    }),
    [isOpen, selectedScenario]
  );

  return (
    <Body header={headerProps}>
      {selectedScenario?.id ? (
        <ScenarioPage
          scenario={selectedScenario}
          toList={handleDeselectScenario}
          refreshScenario={getScenarios}
          openNotification={openNotification}
          api={ApiScenariosManagement}
          tasks={tasks}
        />
      ) : (
        <ScenarioTable
          data={scenarios}
          onHandleRowClick={handleSelectScenario}
          deleteScenario={deleteScenario}
          handleDuplicate={handleDuplicate}
          loading={loading}
        />
      )}
    </Body>
  );
};

export default ScenarioManagement;
