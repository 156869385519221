import { useTranslation } from "react-i18next";
import UserActivityStatus from "../../../components/user-activity-status/user-activity-status";
import getDateByDiff from "../../../common/utils/calendar/get-date-by-diff";
import InfinityTable from "../../../components/infinity-table/infinity-table";
import {TableCell} from "../../../components/table/table-cell/table-cell";
import classNames from "classnames";

const SubjectsList = ({
  subjects,
  fetchUsers,
  hasMore,
  isLoading,
  onHandleRowClick,
}) => {
  const { t } = useTranslation();

  const tableColumns = [
    {
      title: t("subject_management.table.subjectId"),
      dataIndex: "id",
      render: (_, user) => <TableCell title={user.displayId || "N/A"}/>,
      key: "id",
      width: "25%",
    },
    {
      title: t("subject_management.table.cohort"),
      dataIndex: "cohort",
      render: (_, user) => <TableCell text={user.cohort || "N/A"}/>,
      key: "cohort",
      width: "25%",
    },
    {
      title: t("subject_management.table.status"),
      dataIndex: "status",
      key: "status",
      render: (_, user) => <div className={classNames("lf-table-cell")}><UserActivityStatus userStatus={user.status}/></div>,
        width: "25%",
    },
      {
          title: t("subject_management.table.last_active"),
      dataIndex: "last_active",
      key: "last_active",
      render: (_, user) => <TableCell text={getDateByDiff(user.lastActivity)}/>,
    },
  ];

  return (
    <InfinityTable
      data={subjects}
      fetchData={fetchUsers}
      hasMore={hasMore}
      tableColumns={tableColumns}
      isLoading={isLoading}
      onHandleRowClick={onHandleRowClick}
    />
  );
};

export default SubjectsList;
