import { TableCellPicture } from "../../table/table-cell/table-cell-picture";

const Card = ({ page }) => {
  return (
    <div className="simulator-pages-card">
      <div className="simulator-pages-card-image">
        <TableCellPicture proportion={60} pageId={page.id} />
      </div>
      <p>{page.title}</p>
    </div>
  );
};

const SuggestionsCarousel = ({ pages }) => {
  console.log(pages);
  return (
    <div className="simulator-pages-carousel">
      {pages.map((p) => (
        <Card page={p} key={p.id} />
      ))}
    </div>
  );
};

export default SuggestionsCarousel;
