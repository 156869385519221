import "./styles.css";
import { Chips } from "../chips/chips";

const statuses = {
  invited: "Invited",
  inviteError: "Invitation error",
  confirmed: "Active",
  active: "Active",
  blocked: "Blocked",
  inactive: "Inactive",
  PENDING: "Pending",
  pending: "Pending changes",
  COMPLETE: "Complete",
  COMPLETED: "Complete",
  INCOMPLETED: "Incomplete",
  IN_PROGRESS: "In progress",
  OVERDUE: "Overdue",
  DISMISSED: "Dismissed",
};

const getColor = (status) => {
  switch (status) {
    case "IN_PROGRESS":
    case "invited":
    case "PENDING":
    case "pending":
      return "yellow";
    case "inviteError":
    case "OVERDUE":
    case "DISMISSED":
    case "blocked":
      return "red";
    case "confirmed":
    case "active":
    case "COMPLETE":
    case "COMPLETED":
      return "green";
    case "inactive":
    case "INCOMPLETED":
      return "grey";
    default:
      return "grey";
  }
};

const UserActivityStatus = ({ userStatus, fontSize }) => (
  <Chips
    text={statuses[userStatus]}
    color={getColor(userStatus)}
    fontSize={fontSize}
    borderColor={getColor(userStatus)}
  />
);
export default UserActivityStatus;
