import ApiLifeFacts from "../../../api/life-facts";
import AdminResourceTable from "../components/layouts/general/admin-resource-table";
import { Body } from "../../../components/main-body/body";
import { useAdminResource } from "../components/hooks/useAdminResource";
import LifeFactsModal from "./components/life-facts-modal";
import DropdownFilters from "../../../components/dropdown-filters/dropdown-filters";

const LifeFacts = () => {
  const {
    form,
    list,
    active,
    update,
    open,
    close,
    create,
    search,
    handleUpdateSearchValue,
    remove,
    tags,
    updateHasImage,
    updateIsUpdatedImage,
    authors,
    categories,
    factCitations,
    factUrls,
    updateFilterValue,
    isLoading,
  } = useAdminResource(ApiLifeFacts, "facts", "lifefacts.notification");

  const filterItems = [
    { name: "Tag", id: "tags", children: tags },
    {
      name: "Source URL",
      id: "factUrlIds",
      children: factUrls.map((item) => ({
        name: item.title || item.source,
        id: item.id,
      })),
    },
    {
      name: "Source citation",
      id: "factCitationIds",
      children: factCitations.map((item) => ({
        name: item.title || item.source,
        id: item.id,
      })),
    },
    { name: "Category", id: "categoryIds", children: categories },
    { name: "Author", id: "userIds", children: authors },
  ];

  return (
    <Body
      header={{
        title: "FitFacts",
        create: { label: "Create FitFact", onClick: create },
        search: { value: search, onHandleChange: handleUpdateSearchValue },
      }}
      filters={
        <DropdownFilters
          filtersBy={filterItems}
          updateFilterValue={updateFilterValue}
        />
      }
    >
      <LifeFactsModal
        articleId={active?.id}
        form={form}
        data={active}
        onClose={close}
        onSave={update}
        tags={tags}
        updateHasImage={updateHasImage}
        updateIsUpdatedImage={updateIsUpdatedImage}
        authors={authors}
        categories={categories}
        factCitations={factCitations}
        factUrls={factUrls}
        isLoading={isLoading}
      />
      <AdminResourceTable
        data={list}
        onHandleRowClick={open}
        remove={remove}
        tableComlumnKeys={[
          "titleColorMark",
          "categories",
          "updatedAt",
          "action",
        ]}
        isLoading={isLoading}
      />
    </Body>
  );
};

export default LifeFacts;
