import Chart from "./bar-chart";
import healthIcons from "./icons";

const HealthItemCard = ({ item }) => {
  const { title, average, type, sufix, chartValues, nonFormattedAverage } =
    item;

  return (
    <div className="health-item-card">
      <div className="health-item-card__header">
        <img src={healthIcons[type]} alt="health icon" />
        <p>{title}</p>
      </div>
      <div className="health-item-card__subheader">
        <span>{average || 0}</span>
        <span className="health-item-card__subheader__sufix">
          {sufix.toUpperCase()}
        </span>
      </div>
      <div className="health-item-card__body">
        <Chart data={chartValues} average={nonFormattedAverage || average} />
      </div>
    </div>
  );
};

export default HealthItemCard;
