import { memo } from "react";
import { Icon } from "../icon/icon";
import classNames from "classnames";
import { Button,Tooltip} from "antd";
import "./main-navigation.css";
import { useLocation } from "react-router-dom";
import ProfileBar from "../profile-bar/profile-bar";
import { useSelector } from "react-redux";
import { selectIsAdmin } from "../../store/slices/user";

const NavItem = ({ icon, href, disable, title }) => {
  const { pathname } = useLocation();
  const isActive = pathname.includes(href);

  if (disable) {
    return null;
  }
  return (
    <Tooltip title={title} placement={"right"}arrow={false} style={{background: "white"}}>
        <Button
          type={isActive ? "default" : "text"}
          href={href}
          icon={<Icon name={icon} />}
          // style={{ pointerEvents: isActive ? "none" : undefined }}
        ></Button>
    </Tooltip>
  );
};

export const MainNavigation = memo(() => {
  const isAdmin = useSelector(selectIsAdmin);
  return (
    <div className={classNames("lf-main-navigation")}>
      <div className={"lf-main-navigation__header"}>
        <img
          className="lf-main-navigation__logo"
          src={`${
            process.env.REACT_APP_DEV_URL || document.location.origin
          }/api/images/logo.svg`}
          alt="logo"
        />
      </div>
      <div className={"lf-main-navigation__body"}>
        <NavItem icon={"dashboard"} href={"/dashboard"} title={'Dashboard'}/>
        <NavItem icon={"user"} href={"/subject-management"} title={'Subject management'}/>
        <NavItem icon={"reports"} href={"/audit-trail"} disable={!isAdmin} title={'Reports'} />
        <NavItem icon={"setting"} href={"/administration"} disable={!isAdmin} title={'Administration'}/>
      </div>
      <div className={"lf-main-navigation__footer"}>
        <ProfileBar />
      </div>
    </div>
  );
});
