import { memo } from "react";

const IconArrowRight = memo(() => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.96967 2.46967C5.67678 2.76256 5.67678 3.23744 5.96967 3.53033L10.4393 8L5.96967 12.4697C5.67678 12.7626 5.67678 13.2374 5.96967 13.5303C6.26256 13.8232 6.73744 13.8232 7.03033 13.5303L12.0303 8.53033C12.3232 8.23744 12.3232 7.76256 12.0303 7.46967L7.03033 2.46967C6.73744 2.17678 6.26256 2.17678 5.96967 2.46967Z"
        fill="#151515"
      />
    </svg>
  );
});

export default IconArrowRight;
