const IconPlay = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6135_7884)">
        <path
          d="M13.5 6.63398C14.1667 7.01888 14.1667 7.98113 13.5 8.36603L5.25 13.1292C4.58333 13.5141 3.75 13.0329 3.75 12.2631L3.75 2.73686C3.75 1.96706 4.58333 1.48593 5.25 1.87083L13.5 6.63398Z"
          fill="#151515"
        />
      </g>
      <defs>
        <clipPath id="clip0_6135_7884">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconPlay;
