import React from "react";
import { Drawer as AntDrawer } from "antd";
import "./drawer.css";

export const Drawer = ({
  children,
  open,
  onClose,
  placement,
  width,
  ...rest
}) => {
  return (
    <AntDrawer
      className="lf-drawer"
      open={open}
      width={placement === "left" ? 600 : 800}
      closable={false}
      onClose={onClose}
      placement={placement}
      {...rest}
    >
      <div className={"lf-drawer__content"}>{children}</div>
    </AntDrawer>
  );
};
