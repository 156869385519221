import { useEffect, useState } from "react";
import ApiUsersManagement from "../../../api/user-manager";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUsers, setUsers } from "../../../store/slices/user_management";

const useUsers = (search, status) => {
  const { hash } = useLocation();
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const [hasMore, setHasMore] = useState(true);

  const [isLoading, setLoading] = useState(false);

  const getStatusByHash = () => {
    switch (hash) {
      case "#disabled-users":
        return "inactive";
      case "#pending-users":
        return ["invited", "inviteError"];
      default:
        return null;
    }
  };

  const fetchUsers = (params) => {
    setLoading(true);

    const status = getStatusByHash();

    params = { ...params, search, status };

    ApiUsersManagement.getUsers(params)
      .then((r) => {
        const { users, hasMore } = r.data;
        dispatch(setUsers(users));
        setHasMore(hasMore);
      })
      .catch(() => {
        console.log("User management fetch users error.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchUsers();
  }, [hash, search]);

  return { users, hasMore, isLoading, fetchUsers };
};

export default useUsers;
