import { useTranslation } from "react-i18next";
import { Progress } from "antd";
import TableСomponent from "../../../components/table/table";
import { BlockComponent, BlockHeader } from "../../../components/block/block";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";

const AdherenceTable = ({ subjects }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const toSubjectPage = (subject) => {
    navigate(`/subject-management/subject/${subject.id}#tasks`);
  };

  const adherence = [
    {
      title: t("subject_management.table.subjectId"),
      dataIndex: "subjects",
      render: (_, user) => (
        <span style={{ fontWeight: 700 }}>{user.displayId || "N/A"}</span>
      ),
      key: "id",
      width: "25%",
    },
    {
      title: t("subject_management.table.cohort"),
      dataIndex: "cohort",
      render: (_, user) => <span>{user.cohort || "N/A"}</span>,
      key: "cohort",
      width: "25%",
    },
    {
      title: t("subject_management.table.adherence"),
      dataIndex: "adherence",
      render: (_, user) => (
        <Progress size="small" percent={Math.floor(user.adherence * 100)} />
      ),
      key: "adherence",
      width: "25%",
    },
    {
      title: t("subject_management.table.daily_points"),
      dataIndex: "points",
      render: (_, user) => <span>{user.daily_points || "N/A"}</span>,
      key: "points",
      width: "25%",
      align: "right",
    },
  ];

  const adherenceData = useMemo(
    () => [
      ...subjects.map((s) => {
        return {
          id: s.id,
          displayId: s.displayId,
          cohort: s.cohort,
          adherence: s.adherence || 0,
          daily_points: s.totalScore,
        };
      }),
    ],
    [subjects]
  );

  return (
    <BlockComponent>
      <BlockHeader title={"Adherence"} size={"md"} />
      <TableСomponent
        columns={adherence}
        data={adherenceData}
        onHandleRowClick={toSubjectPage}
      />
    </BlockComponent>
  );
};

export default AdherenceTable;
