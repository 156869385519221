export function truncateText(text, maxLength = 50) {
  const truncate = (str, max) => {
    if (str.length <= max) {
      return str;
    }

    let truncated = str.substring(0, max);
    const lastSpaceIndex = truncated.lastIndexOf(" ");

    if (lastSpaceIndex > -1) {
      truncated = truncated.substring(0, lastSpaceIndex);
    }

    return truncated + "...";
  };

  return truncate(text, maxLength);
}
