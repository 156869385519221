import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  clearScenarioTrails,
  fetchScenarioTrails,
  selecthasMoreScenarioTrails,
  selectScenarioTrails,
  selectScenarioTrailsLoading,
} from "../../../../store/slices/scenario-trails";
import AuditTrailHeader from "../audit-trail-header";
import "../../styles.css";
import InfinityTable from "../../../../components/infinity-table/infinity-table";
// import ApiScenariosManagement from "../../../../api/scenarios-management";
import ApiSubjectsManagement from "../../../../api/subjects-manager";
import ApiTrails from "../../../../api/trail";
import moment from "moment";
import { Body } from "../../../../components/main-body/body";
import "../../../../components/main-body/body.css";
import classNames from "classnames";

const ScenariosTrailTable = () => {
  const trails = useSelector(selectScenarioTrails);
  const hasMore = useSelector(selecthasMoreScenarioTrails);
  const loading = useSelector(selectScenarioTrailsLoading);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [subjectsList, setSubjectsList] = useState([]);

  const fetchSubjectsList = () => {
    ApiSubjectsManagement.getSubjects().then((r) => {
      setSubjectsList(
        r.data.subjects.map((s) => ({
          id: s.displayId,
          name: s.displayId,
        }))
      );
    });
  };

  useEffect(() => {
    fetchSubjectsList();
  }, []);

  const fetchData = (currentPage = page) => {
    if (!loading) {
      setPage(currentPage + 1);
      dispatch(
        fetchScenarioTrails({
          page: currentPage,
          ...filters,
          createdAtEnd: filters.period?.[filters.period.length - 1]?.end,
          createdAtStart: filters.period?.[filters.period.length - 1]?.start,
        })
      );
    }
  };

  const updateFilterValue = (filter) => {
    setFilters(filter);
  };

  useEffect(() => {
    if (trails) {
      dispatch(clearScenarioTrails());
    }
    fetchData(1);
  }, [filters]);

  const columns = [
    {
      title: t("trails.table.subjectId"),
      dataIndex: "id",
      render: (_, trail) => <span>{trail.subjectId || "N/A"}</span>,
      key: "id",
    },
    {
      title: t("trails.table.time"),
      dataIndex: "time",
      key: "time",
      render: (_, trail) => (
        <span>
          {`${moment(trail.createdAt)
            .utc()
            .format("DD MMM YYYY, HH:mm")}, UTC` || "N/A"}
        </span>
      ),
    },
    {
      title: t("trails.table.scenarioName"),
      dataIndex: "scenario",
      key: "scenario",
      render: (_, trail) => (
        <span style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {trail.scenario || "N/A"}
        </span>
      ),
    },
    {
      title: t("trails.table.question"),
      dataIndex: "question",
      key: "question",
      render: (_, trail) => (
        <span style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {trail.question || "N/A"}
        </span>
      ),
    },
    {
      title: t("trails.table.response"),
      dataIndex: "response",
      key: "response",
      render: (_, trail) => (
        <span style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {trail.response || "N/A"}
        </span>
      ),
    },
    {
      title: t("trails.table.status"),
      dataIndex: "status",
      key: "status",
      render: (_, trail) => (
        <span style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {trail.status || "N/A"}
        </span>
      ),
    },
  ];

  const filterItems = [
    { name: "Date peroid", id: "period", period: true },
    { name: "Subject", id: "displayId", children: subjectsList },
  ];

  const exportTrail = () => {
    ApiTrails.exportScenarioTrails();
  };

  return (
    <Body disabledPaddings={true}>
      <AuditTrailHeader
        t={t}
        filterItems={filterItems}
        updateFilterValue={updateFilterValue}
        exportTrail={exportTrail}
      />
      <div className={classNames("lf-content")}>
        <InfinityTable
          data={trails}
          fetchData={fetchData}
          hasMore={!!hasMore}
          tableColumns={columns}
          isLoading={loading}
        />
      </div>
    </Body>
  );
};

export default ScenariosTrailTable;
