import axios from "axios";

const getTasks = () => axios.get("api/v1/task-managements");

const addTask = (task) => axios.post("api/v1/task-managements", task);

const updateTask = (id, task) =>
  axios.put(`api/v1/task-managements/${id}`, task);

const deleteTask = (id) => axios.delete(`api/v1/task-managements/${id}`);

const ApiTaskManagement = {
  getTasks,
  addTask,
  updateTask,
  deleteTask,
};

export default ApiTaskManagement;
