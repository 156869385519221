import Answer from "./answer";
import Question from "./question";
import { useState } from "react";
import AnswerDrawer from "./answer-drawer";
import { DatePicker } from "antd";
import moment from "moment";

const DateTimePicker = ({ data, setAnswer, answeredLabel, inProcess }) => {
  const [isOpen, setIsOpen] = useState();
  const [value, setValue] = useState();

  const updateAnswers = (item) => {
    if (item === null) {
      setValue();
      return;
    }

    setValue(item.$d);
  };

  const handleSubmit = () => {
    if (!value) return;
    setAnswer({ answer: moment(value).format(data.format) });
    setIsOpen(false);
  };

  return (
    <div className="multiple-choice">
      <Question text={data.message} />
      <div className="multiple-choice-answers">
        {answeredLabel ? (
          <Answer
            key={`Its answer ${Math.random()}`}
            text={answeredLabel}
            selected={answeredLabel}
            onClick={() => setAnswer()}
          />
        ) : (
          <Answer
            key={`Select answers${Math.random()}`}
            text={data.placeholder ?? "Select date…"}
            onClick={() => setIsOpen(true)}
          />
        )}
      </div>

      <AnswerDrawer
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title={data.message}
        submit={handleSubmit}
        height={240}
      >
        <div className="multiple-choice-answers">
          <DatePicker
            className="ant-picker-panel-scenario-container"
            showTime={data.format.includes("HH") ? { format: "HH:mm" } : false}
            format={data.format}
            size="large"
            style={{ width: "100%" }}
            onChange={updateAnswers}
            placeholder={data.placeholder ?? "Select date…"}
          />
        </div>
      </AnswerDrawer>
    </div>
  );
};

export default DateTimePicker;
