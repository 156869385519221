import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CategoryTable from "./category-table";
import CategoryCard from "./category-card/category-card";
import ApiCategoryManagement from "../../../api/category-managements";
import useNotification from "../../../components/notification/notification";
import "./styles.css";
import { Body } from "../../../components/main-body/body";
import { useForm } from "antd/es/form/Form";
import { Modal } from "antd";

const DeleteModalComponent = ({
  item,
  handleOk,
  handleCancel,
  isModalOpen,
}) => {
  return (
    <Modal
      title={item?.name}
      open={isModalOpen}
      onOk={() => {
        handleOk(item.id, item.name);
      }}
      okText="Delete"
      onCancel={handleCancel}
      okButtonProps={{ danger: true }}
      className="plan-page-action-modal"
    >
      <p>Are you sure you want to delete this category?</p>
    </Modal>
  );
};

const CategoryManagement = () => {
  const [categories, setCategories] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [editMode, setEditMode] = useState();
  const [selectedCategory, setSelectedCategory] = useState({});
  const [form] = useForm();
  const { t } = useTranslation();
  const { openNotification } = useNotification();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const openCategoryDrawer = (edit) => {
    if (selectedCategory?.name) {
      setSelectedCategory({});
    }
    if (edit) {
      setEditMode(true);
    }
    setOpen(true);
  };

  const closeCategoryDrawer = () => {
    setSelectedCategory({});
    setEditMode(false);
    setOpen(false);
  };

  const headerProps = useMemo(
    () => ({
      title: "Category Management",
      create: {
        label: t("administration.category.create"),
        onClick: () => openCategoryDrawer(true),
      },
    }),
    [isOpen, selectedCategory]
  );

  const getCategories = () => {
    ApiCategoryManagement.getCategories().then(({ data }) => {
      const { categories } = data || {};

      setCategories(categories);
    });
  };

  useEffect(() => {
    getCategories();
  }, []);

  const createCategory = (category) => {
    closeCategoryDrawer();
    ApiCategoryManagement.addCategory(category)
      .then(() => {
        getCategories();
      })
      .then(() => {
        openNotification({
          type: "success",
          message: (
            <p>
              {t("administration.category.notification.created", {
                category: category.name,
              })}
            </p>
          ),
        });
      })
      .catch((e) => {
        openNotification({
          type: "error",
          message: (
            <p>
              {t(
                `administration.category.notification.${
                  e?.response?.data?.error?.code || "error"
                }`
              )}
            </p>
          ),
        });
      });
  };

  const updateCategory = (id, category) => {
    closeCategoryDrawer();
    ApiCategoryManagement.updateCategory(id, category)
      .then(() => {
        getCategories();
      })
      .then(() => {
        openNotification({
          type: "success",
          message: (
            <p>
              {t("administration.category.notification.updated", {
                category: category.name,
              })}
            </p>
          ),
        });
      })
      .catch((e) => {
        openNotification({
          type: "error",
          message: (
            <p>
              {t(
                `administration.category.notification.${
                  e?.response?.data?.error?.code || "error"
                }`
              )}
            </p>
          ),
        });
      });
  };

  const onHandleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const onCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const deleteCategory = (id, name) => {
    setIsDeleteModalOpen(false);
    closeCategoryDrawer();
    ApiCategoryManagement.deleteCategory(id)
      .then(() => {
        getCategories();
      })
      .then(() => {
        openNotification({
          type: "success",
          message: (
            <p>
              {t("administration.category.notification.deleted", {
                category: name,
              })}
            </p>
          ),
        });
      })
      .catch((e) => {
        openNotification({
          type: "error",
          message: (
            <p>
              {t(
                `administration.category.notification.${
                  e?.response?.data?.error || "error"
                }`
              )}
            </p>
          ),
        });
      });
  };

  const handleSelectCategory = (category) => {
    setSelectedCategory(category);
    setOpen(true);
  };

  const toEditMode = () => {
    setEditMode(true);
  };

  useEffect(() => {
    form.resetFields();
  }, [selectedCategory, editMode]);

  return (
    <Body header={headerProps}>
      <CategoryTable
        data={categories}
        onHandleRowClick={handleSelectCategory}
      />
      <CategoryCard
        category={selectedCategory}
        isOpen={isOpen}
        close={closeCategoryDrawer}
        editMode={editMode}
        toEditMode={toEditMode}
        createCategory={createCategory}
        updateCategory={updateCategory}
        deleteCategory={onHandleDelete}
        form={form}
      />
      {
        <DeleteModalComponent
          item={selectedCategory}
          isModalOpen={isDeleteModalOpen}
          handleCancel={onCloseDeleteModal}
          handleOk={deleteCategory}
        />
      }
    </Body>
  );
};

export default CategoryManagement;
