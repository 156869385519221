import { memo } from "react";
import { Dropdown } from "antd";
import Icons from "../../../../components/icons/icons";
import encodeSvgWithColor from "../../../../common/utils/encodeSvgWithColor";

export const TaskIconPicker = memo(({ task, onIconSelect }) => {
  return (
    <Dropdown
      trigger={"click"}
      //eslint-disable-next-line
      overlay={
        <div className="lf-task-icon-picker">
          <Icons onIconSelect={onIconSelect} activeColor={task.avatarColor} />
        </div>
      }
    >
      <div
        className="icon-wrapper"
        style={{
          width: 72,
          height: 72,
          backgroundColor: task.backgroundColor,
          marginBottom: 10,
          cursor: onIconSelect ? "pointer" : undefined,
          pointerEvents: onIconSelect ? "all" : "none",
        }}
      >
        <img
          src={encodeSvgWithColor(task.avatar, task.avatarColor)}
          alt="avatar"
        />
      </div>
    </Dropdown>
  );
});
