import { useMemo } from "react";
import { Button, Divider, Form, Input, Progress, Spin } from "antd";
import CompanyBrand from "../../components/company-brand/company-brand";
import "./styles.css";
import ValidationChecklist from "../../components/validation-checklist/validation-checklist";
import { LoadingOutlined } from "@ant-design/icons";

function NewPassView({
  form,
  t,
  inProgress,
  newPassword,
  errorMsg,
  onChange,
  errorFields,
  checkToken,
  tokenError,
  showSubjectComplete,
}) {
  const currentErrorsTexts = [
    t("login.validation_msg.not_contain_name_mail"),
    t("login.validation_msg.min_characters"),
    t("login.validation_msg.contain_num_symbol"),
  ];
  const validationRules = currentErrorsTexts.reduce(
    (acc, rule) => [
      ...acc,
      {
        text: rule,
        invalidColor: "#B2B2C7",
        validColorCheckbox: "rgba(54, 179, 126, 1)",
        validColorText: "rgba(21, 21, 21, 1)",
      },
    ],
    []
  );

  const errorTokenContainer = useMemo(() => {
    if (checkToken || tokenError) {
      return (
        <div className="auth__token-checker">
          <div className="auth__token-checker__content">
            {tokenError ? (
              <>
                <Progress
                  size="small"
                  type="circle"
                  percent={100}
                  status="exception"
                />
                <p>{t(`login.errors.${tokenError}`)}</p>
              </>
            ) : (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
              />
            )}
          </div>
        </div>
      );
    }

    return null;
  }, [checkToken, tokenError, t]);

  if (showSubjectComplete) {
    return (
      <div className="auth">
        <div className="auth__container">
          <div className="auth__header">
            <CompanyBrand />
            <Divider style={{ marginBlock: 40 }} />
            <h1>{t("login.new_pass.subject_complete")}</h1>
          </div>
          <div className="auth__body">
            <p>Please use mobile app to log in.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="auth">
      <div className="auth__container">
        <div className="auth__header">
          <CompanyBrand />
          <Divider style={{ marginBlock: 40 }} />
          <h1>{t("login.new_pass.title")}</h1>
        </div>
        <div className="auth__body">
          {errorTokenContainer}
          <Form form={form} layout="vertical" onFinish={newPassword}>
            <Form.Item
              name="password"
              data-testid="password-label"
              rules={[
                {
                  required: true,
                  message: t("login.validation_msg.password"),
                },
                {
                  type: "string",
                  min: 8,
                  message: t("login.validation_msg.min_characters"),
                },
                {
                  pattern: /(?=.*\d)(?=.*[a-z])(?=.*\W)/,
                  message: t("login.validation_msg.contain_num_symbol"),
                },
              ]}
            >
              <Input.Password
                placeholder={t("login.password")}
                size="large"
                disabled={inProgress}
                data-testid="password-input"
                autoComplete="off"
                aria-autocomplete="none"
                list="autocompleteOff"
                maxLength={30}
              />
            </Form.Item>
            <Form.Item
              name="confirm"
              data-testid="confirm-password-label"
              dependencies={["password"]}
              onChange={onChange}
              rules={[
                {
                  required: true,
                  message: t("login.validation_msg.password"),
                },
                {
                  pattern: /(?=.*\d)(?=.*[a-z])(?=.*\W)/,
                  message: t("login.validation_msg.contain_num_symbol"),
                },
                {
                  type: "string",
                  min: 8,
                  message: t("login.validation_msg.min_characters"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("login.validation_msg.match_password"))
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder={t("login.confirm_password")}
                size="large"
                disabled={inProgress}
                data-testid="confirm-password-input"
                autoComplete="off"
                aria-autocomplete="none"
                list="autocompleteOff"
                maxLength={30}
              />
            </Form.Item>
            {errorMsg && (
              <Form.Item>
                <p className="error-msg" data-testid="error">
                  {errorMsg}
                </p>
              </Form.Item>
            )}
            <Form.Item>
              <ValidationChecklist
                validationRules={validationRules}
                errorFields={errorFields}
                touched={form.isFieldsTouched(["password", "confirm"])}
                varificationValue={form.getFieldValue("confirm")}
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                disabled={inProgress}
                style={{ marginTop: 20 }}
                data-testid="submit-new-password"
              >
                {t("login.submit")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default NewPassView;
