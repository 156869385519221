import moment from "moment";
import { useTranslation } from "react-i18next";
import InfinityTable from "../../../components/infinity-table/infinity-table";

const CategoryTable = ({ data, onHandleRowClick }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("administration.category.table.name"),
      dataIndex: "title",
      render: (_, category) => <span>{category.name || "N/A"}</span>,
      key: "title",
      width: "30%",
    },
    {
      title: t("administration.category.table.last_modified"),
      dataIndex: "updatedAt",
      render: (_, category) => (
        <span>
          {`${moment(category.updatedAt)
            .utc()
            .format("DD MMM YYYY, HH:mm")}, UTC` || "N/A"}
        </span>
      ),
      key: "updatedAt",
      width: "30%",
    },
  ];
  return (
    <InfinityTable
      data={data}
      fetchData={() => console.log("fetchData")}
      hasMore={false}
      tableColumns={columns}
      isLoading={false}
      onHandleRowClick={onHandleRowClick}
    />
  );
};

export default CategoryTable;
