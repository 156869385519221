import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { hasUserToken } from "../../store/slices/user";

const PrivateRoutes = () => {
  const auth = useSelector(hasUserToken);

  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
