import { useEffect, useState } from "react";
import { Button, Form } from "antd";
import { Body } from "../../../components/main-body/body";
import editIcon from "../../../assets/edit.svg";
import { useTranslation } from "react-i18next";
import useNotification from "../../../components/notification/notification";
import "./life-points.css";
import ApiLifePointsManagement from "../../../api/lifepoints";
import { FormInput } from "../../../components/form/form";
import classNames from "classnames";

const LifePoints = () => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const { openNotification } = useNotification();
  const [pointsList, setPointsList] = useState([]);
  const [initialList, setInitial] = useState([]);
  const [form] = Form.useForm();

  const cancel = () => {
    setIsEdit(false);
    setHasChanges(false);
    setPointsList(initialList);
  };

  const toogleEdit = () => {
    setIsEdit(!isEdit);
    setHasChanges(false);
  };

  const updatePoints = () => {
    ApiLifePointsManagement.update(pointsList).then(() => {
      openNotification({
        type: "success",
        message: <p>{t("lifepoints.notification.success")}</p>,
      });
    });
    toogleEdit();
  };

  const getLifePoints = () => {
    ApiLifePointsManagement.get().then((r) => {
      setPointsList(r.data.scores);
      setInitial(r.data.scores);
    });
  };

  const onHandleChange = (name, v) => {
    if (!hasChanges) {
      setHasChanges(true);
    }
    setPointsList(
      pointsList.map((item) => {
        if (item.name === name) {
          return { ...item, points: +v.target.value };
        }

        return item;
      })
    );
  };

  useEffect(() => {
    getLifePoints();
  }, []);

  return (
    <Body header={{ title: t("lifepoints.title") }}>
      <div className="profile_form-container">
        <div className="profile_form__header">
          <span className="profile_form__header__title">
            {t("lifepoints.mapping")}
          </span>
          <div style={{ height: 40 }}>
            {isEdit ? (
              <div className="profile-header__btns">
                <Button
                  style={{
                    height: 40,
                    background: "rgba(108, 108, 137, 0.08)",
                  }}
                  onClick={cancel}
                >
                  {t("profile.btns.cancel")}
                </Button>
                <Button
                  type="primary"
                  style={{ marginLeft: 8, height: 40 }}
                  onClick={updatePoints}
                  disabled={!hasChanges}
                >
                  {t("profile.btns.save")}
                </Button>
              </div>
            ) : (
              <img
                className="profile_form__header__edit-btn"
                src={editIcon}
                alt="edit"
                role="presentation"
                onClick={toogleEdit}
              />
            )}
          </div>
        </div>

        <div className="lifepoints-content-container">
          <div className="lifepoints-content-item">
            <p className="lifepoints-content-item-title">
              {t("lifepoints.table.activity")}
            </p>
            <div>
              {pointsList.map((item) => (
                <p className="lifepoints-content-item-activity">{item.name}</p>
              ))}
            </div>
          </div>
          <div className="lifepoints-content-item">
            <p className="lifepoints-content-item-title">
              {t("lifepoints.table.points")}
            </p>
            <div>
              <Form form={form}>
                {pointsList.map((item) => (
                  <FormInput
                    name={item.name}
                    key={item.id}
                    readOnly={!isEdit}
                    type="number"
                    className={classNames("lifepoints-content-item-points", {
                      "lifepoints-content-item-points--edit-mode": isEdit,
                    })}
                    value={item.points}
                    bordered={false}
                    onChange={(v) => onHandleChange(item.name, v)}
                  />
                ))}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Body>
  );
};

export default LifePoints;
