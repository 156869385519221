import { memo } from "react";

const IconSetting = memo(() => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0001 1.64998C4.97837 1.64998 4.1501 2.47825 4.1501 3.49998C4.1501 4.5217 4.97837 5.34998 6.0001 5.34998C7.02182 5.34998 7.8501 4.5217 7.8501 3.49998C7.8501 2.47825 7.02182 1.64998 6.0001 1.64998ZM6.0001 6.64998C4.48323 6.64998 3.21678 5.57781 2.91724 4.14998H1.5001C1.14111 4.14998 0.850098 3.85896 0.850098 3.49998C0.850098 3.14099 1.14111 2.84998 1.5001 2.84998H2.91724C3.21678 1.42214 4.48323 0.349976 6.0001 0.349976C7.73979 0.349976 9.1501 1.76028 9.1501 3.49998C9.1501 5.23967 7.73979 6.64998 6.0001 6.64998ZM11.5001 2.84998C11.1411 2.84998 10.8501 3.14099 10.8501 3.49998C10.8501 3.85896 11.1411 4.14998 11.5001 4.14998H16.5001C16.8591 4.14998 17.1501 3.85896 17.1501 3.49998C17.1501 3.14099 16.8591 2.84998 16.5001 2.84998H11.5001ZM0.850098 12.5C0.850098 12.141 1.14111 11.85 1.5001 11.85H6.5001C6.85908 11.85 7.1501 12.141 7.1501 12.5C7.1501 12.859 6.85908 13.15 6.5001 13.15H1.5001C1.14111 13.15 0.850098 12.859 0.850098 12.5ZM13.8501 12.5C13.8501 11.4782 13.0218 10.65 12.0001 10.65C10.9784 10.65 10.1501 11.4782 10.1501 12.5C10.1501 13.5217 10.9784 14.35 12.0001 14.35C13.0218 14.35 13.8501 13.5217 13.8501 12.5ZM16.5001 11.85H15.083C14.7834 10.4221 13.517 9.34998 12.0001 9.34998C10.2604 9.34998 8.8501 10.7603 8.8501 12.5C8.8501 14.2397 10.2604 15.65 12.0001 15.65C13.517 15.65 14.7834 14.5778 15.083 13.15H16.5001C16.8591 13.15 17.1501 12.859 17.1501 12.5C17.1501 12.141 16.8591 11.85 16.5001 11.85Z"
        fill="#212121"
      />
    </svg>
  );
});

export default IconSetting;
