import { DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";

const ActionsDropdown = ({ remove }) => {
  const items = [
    {
      key: "1",
      label: "Delete",
      icon: <DeleteOutlined />,
      onClick: () => remove(),
    },
  ];

  return (
    <Dropdown
      menu={{
        items,
        onClick: (e) => {
          e.domEvent.stopPropagation();
        },
      }}
      trigger={["click"]}
      overlayStyle={{ width: 120 }}
    >
      <EllipsisOutlined
        style={{ cursor: "pointer", width: 30 }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </Dropdown>
  );
};

export default ActionsDropdown;
