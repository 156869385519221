import "./styles.css";
import iconsCollection from "../../assets/icons.json";
import svgBase64 from "../../common/utils/svgBase64";
import { useEffect, useState } from "react";
import { Button } from "antd";

export const colors = [
  {
    backgroundColor: "#F2DCD8",
    color: "#AA3833",
  },
  {
    backgroundColor: "#F8D8B8",
    color: "#864100",
  },
  {
    backgroundColor: "#F9EBB7",
    color: "#8D5E00",
  },
  {
    backgroundColor: "#EAEBC9",
    color: "#817C03",
  },
  {
    backgroundColor: "#DDEBC9",
    color: "#598B0E",
  },
  {
    backgroundColor: "#A3F1CB",
    color: "#1D764B",
  },
  {
    backgroundColor: "#B0E5F5",
    color: "#1E6379",
  },
  {
    backgroundColor: "#CAD5F8",
    color: "#012FB5",
  },
  {
    backgroundColor: "#D7C8F7",
    color: "#5013D5",
  },
  {
    backgroundColor: "#ECCEF0",
    color: "#8B009D",
  },
  {
    backgroundColor: "#F7D2DF",
    color: "#A30039",
  },
];

export const ColorPicker = ({ onSelect, activeColor }) => {
  const colorsSelector = colors.map(({ backgroundColor, color, active }) => (
    <div
      style={{ backgroundColor }}
      className="icon-color-item"
      onClick={() => {
        onSelect({ avatarColor: color, backgroundColor });
      }}
    >
      {activeColor === color && (
        <div
          key={color}
          className={"icon-color-item__dot"}
          style={{ backgroundColor: color }}
        ></div>
      )}
    </div>
  ));

  return <div className="icons-color-selector-container">{colorsSelector}</div>;
};

const { icons } = iconsCollection;

const Icons = ({ onIconSelect, activeColor, activeIcon }) => {
  const [icon, setIcon] = useState();
  const [iconColor, setIconColor] = useState({});
  const [hasChanges, setHasChanges] = useState();

  useEffect(() => {
    console.log(onIconSelect);
    if (onIconSelect && hasChanges) {
      onIconSelect({
        avatar: icon,
        avatarColor: iconColor.avatarColor || "#1E6379",
        backgroundColor: iconColor.backgroundColor || "#B0E5F5",
      });
    }
  }, [icon, iconColor]);

  const handleSelectColor = (color) => {
    if (!hasChanges) {
      setHasChanges(true);
    }
    setIconColor(color);
  };

  const handleSelectIcon = (icon) => {
    if (!hasChanges) {
      setHasChanges(true);
    }
    setIcon(icon);
  };

  const iconsList = icons.map((i) => {
    const content = svgBase64(i.content);
    return (
      <Button
        onClick={() => handleSelectIcon(i.content)}
        type={"text"}
        icon={<img style={{ width: 16 }} src={content} alt={i.name} />}
      ></Button>
    );
  });

  return (
    <div className="icons-container">
      <div className="icons-catalog-search"></div>
      <ColorPicker onSelect={handleSelectColor} activeColor={activeColor} />
      <div className="icons-catalog">{iconsList}</div>
    </div>
  );
};

export default Icons;
