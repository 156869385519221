import "./block.css";
import "./block-table.css";
import classNames from "classnames";
import { forwardRef, useEffect, useRef, useState } from "react";
import useResizeObserver from "use-resize-observer";
import { Icon } from "../icon/icon";

export const BlockComponent = ({ children, style, fixThead, padding, className }) => {
  return (
    <div
      className={classNames("lf-block", className, { "lf-block--fix-thead": fixThead })}
      style={{
        ...style,
        '--block-side-padding': padding ? `${padding}px` : '32px'
      }}
    >
      <div className={"lf-block__before"}>
        <div className={"lf-block__before-inside"} />
      </div>
      {children}
    </div>
  );
};

export const BlockHeader = ({ title, size = "sm" , onClick}) => {
  return (
    <div onClick={onClick} className="lf-block-header" data-size={size} style={{cursor: onClick ? 'pointer' : 'default'}}>
      <h4>{title}</h4>
    </div>
  );
};

// export const BlockTabs = ({ path = "", items = [] }) => {
//   const navigate = useNavigate();
//   const [currentKey, setCurrentKey] = useState();
//
//   const selectItem = (item) => {
//     const { key } = item;
//     const linkItem = items.filter((i) => i.link && i.id === key).length;
//
//     if (linkItem) {
//       navigate(`/${key}`);
//       return;
//     }
//
//     if (key) {
//       setCurrentKey(key);
//       navigate(`${path}#${key}`);
//     } else {
//       setCurrentKey(items.filter((i) => i.default)[0].id);
//       navigate(`${path}`);
//     }
//   };
//
//   return (
//     <div className="lf-block-section">
//       <div className="lf-block-tabs">
//         {items.map((item) => {
//           return (
//             <div className={"lf-block-tabs__item"}>
//               <Button
//                 isSelected={item.key === currentKey}
//                 type={"text"}
//                 onClick={() => selectItem(item)}
//               >
//                 {item.name}
//               </Button>
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };


export const BlockSection = forwardRef((props,ref) => {
  return <div ref={ref} className={classNames("lf-block-section", props.className)} style={props.style}>{props.children}</div>;
});

export const BlockAdd = forwardRef(({ onClick, title, style, hide }, ref) => {
  return (
    <div className={classNames('lf-block-add',{'lf-block-add--hide': hide})} onClick={onClick} style={style}>
      <Icon
        ref={ref}
        name={"add"}
        style={{marginRight:4}}
      />
        {title || "Add"}
    </div>
  );
});

export const BlockCollapse = ({ title, children, after }) => {
  const [isOpen, setOpen] = useState(false);
  const [isMount, setIsMount] = useState(false);
  const ref = useRef();
  const {height} = useResizeObserver({
    ref,
    box: 'border-box'
  });

  const toggleHandler = () => {
    if(isOpen){
      setIsMount(false);
      setTimeout(()=>{
        setOpen(false);
      },100)
    } else {
      setOpen(true);
      setTimeout(()=>{
        setIsMount(true);
      },1)
    }

  };

  useEffect(() => {
    console.log(height)
  }, [height]);

  return(
    <div className={classNames(
        'lf-block-collapse', {
          'lf-block-collapse--open': isOpen
        })}>
    <div
      className={'lf-block-collapse__header'}
      onClick={toggleHandler}
    >
      <Icon name={'arrow-right'} color={'text-2'}/>
      <div className='lf-block-collapse__title'>
        {title}
      </div>
      <div className='lf-block-collapse__after'>
        {after}
      </div>
    </div>
    {isOpen &&
      <div
        className={'lf-block-collapse__body'}
        style={{ height: isMount ? height + 24 : 0, }}
      >
        <div ref={ref}>
          {children}
        </div>
    </div>
    }
</div>
  )
}

export default Object.assign(BlockComponent, {
  header: BlockHeader,
  section: BlockSection,
  Collapse: BlockCollapse
});
