import { memo } from "react";

const IconStop = memo(() => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 1H2C1.447 1 1 1.447 1 2V22C1 22.553 1.447 23 2 23H22C22.553 23 23 22.553 23 22V2C23 1.447 22.553 1 22 1Z"
        fill="#212121"
      />
    </svg>
  );
});

export default IconStop;
