import axios from "axios";

const getCohorts = () => axios.get("api/v1/cohorts");

const addCohort = (cohort) => axios.post("api/v1/cohorts", cohort);

const updateCohort = (id, cohort) => axios.put(`api/v1/cohorts/${id}`, cohort);

const deleteCohort = (id) => axios.delete(`api/v1/cohorts/${id}`);

const ApiCohortManagement = {
  getCohorts,
  addCohort,
  updateCohort,
  deleteCohort,
};

export default ApiCohortManagement;
