import { memo } from "react";

const IconClose = memo(() => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2803 3.28033C14.5732 2.98744 14.5732 2.51256 14.2803 2.21967C13.9874 1.92678 13.5126 1.92678 13.2197 2.21967L8.25 7.18934L3.28033 2.21967C2.98744 1.92678 2.51256 1.92678 2.21967 2.21967C1.92678 2.51256 1.92678 2.98744 2.21967 3.28033L7.18934 8.25L2.21967 13.2197C1.92678 13.5126 1.92678 13.9874 2.21967 14.2803C2.51256 14.5732 2.98744 14.5732 3.28033 14.2803L8.25 9.31066L13.2197 14.2803C13.5126 14.5732 13.9874 14.5732 14.2803 14.2803C14.5732 13.9874 14.5732 13.5126 14.2803 13.2197L9.31066 8.25L14.2803 3.28033Z"
        fill="#151515"
      />
    </svg>
  );
});

export default IconClose;
