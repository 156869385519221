import { Modal } from "antd";

const DeleteModal = ({
  title,
  question = "Are you sure you want to delete this item?",
  handleOk,
  handleCancel,
  isModalOpen,
}) => {
  return (
    <Modal
      title={title}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ danger: true }}
      className="plan-page-action-modal"
    >
      <p>{question}</p>
    </Modal>
  );
};

export default DeleteModal;
