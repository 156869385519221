import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./styles.css";

const PageContentLoader = () => {
  return (
    <div className="page-content-loader">
      <div className="page-content-loader-box">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    </div>
  );
};

export default PageContentLoader;
