import moment from "moment";
import { useEffect, useState } from "react";
import { LineChart, CartesianGrid, XAxis, Tooltip, Line } from "recharts";

const Chart = ({ pointsByDates, width }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    let data = [];

    for (const [date, points] of Object.entries(pointsByDates)) {
      data.push({
        name: moment(date).format("DD MMM"),
        points: +points,
      });
    }

    setChartData(data);
  }, [pointsByDates]);

  return (
    <LineChart
      width={width}
      height={300}
      data={chartData}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <Tooltip animationDuration={50} />
      <Line type="linear" dataKey="points" isAnimationActive={false} stroke="#000" />
    </LineChart>
  );
};

export default Chart;
