import CategoryCardView from "./view";
import CategoryCardEdit from "./edit";
import ModalWrapper from "../../../../components/modal/modal";
import { useTranslation } from "react-i18next";

const CategoryCard = ({
  category,
  isOpen,
  close,
  editMode,
  createCategory,
  updateCategory,
  deleteCategory,
  toEditMode,
  form,
}) => {
  const { t } = useTranslation();

  const create = () => {
    const { name, description } = form.getFieldsValue();
    if (!category.id) {
      createCategory({
        name,
        description,
      });
    } else {
      updateCategory(category.id, {
        ...category,
        name,
        description,
      });
    }
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      title={category.id ? "Category" : "Create category"}
      onOk={create}
      onCancel={close}
      okText={t(
        category.id ? "categories.modal.update" : "categories.modal.create"
      )}
      okButtonProps={{
        size: "large",
        style: { display: editMode ? "" : "none" },
      }}
      cancelButtonProps={{
        size: "large",
        style: {
          backgroundColor: "rgba(108, 108, 137, 0.08)",
          display: editMode ? "" : "none",
        },
      }}
    >
      {editMode ? (
        <CategoryCardEdit
          task={category}
          createCategory={createCategory}
          close={close}
          updateCategory={updateCategory}
          deleteCategory={deleteCategory}
          form={form}
        />
      ) : (
        <CategoryCardView
          category={category}
          close={close}
          toEditMode={toEditMode}
          deleteCategory={deleteCategory}
          form={form}
        />
      )}
    </ModalWrapper>
  );
};

export default CategoryCard;
