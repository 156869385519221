import { memo } from "react";

const IconArrowLeft = memo(() => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0303 2.46967C10.3232 2.76256 10.3232 3.23744 10.0303 3.53033L5.56066 8L10.0303 12.4697C10.3232 12.7626 10.3232 13.2374 10.0303 13.5303C9.73744 13.8232 9.26256 13.8232 8.96967 13.5303L3.96967 8.53033C3.67678 8.23744 3.67678 7.76256 3.96967 7.46967L8.96967 2.46967C9.26256 2.17678 9.73744 2.17678 10.0303 2.46967Z"
        fill="#151515"
      />
    </svg>
  );
});

export default IconArrowLeft;
