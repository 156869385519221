import React, { memo } from "react";
import classNames from "classnames";
import "./icon.css";
import { getColor } from "../_utils/get-color";
/**
 * @param {dashboard|user} name
 * @param {number} size
 */

export const Icon = memo(({ name, size, color, hidden, style }) => {
  const IconComponent = require(`./icons/${name}`).default;
  return (
    <div
      className={classNames("lf-icon")}
      style={{
        "--lf-icon-color": getColor(color),
        "--lf-icon-size": `${size || 16}px`,
        opacity: hidden ? 0 : 1,
        ...style
      }}
    >
      <IconComponent />
    </div>
  );
});
