const colors = [
  "bg-layout-color",
  "shade-color",
  "border-color",
  "text-0",
  "text-1",
  "text-2",
  "red",
  "green",
  "yellow",
  "violet",
  "blue",
  "bg-color",
  "brand",
];
export const getColor = (color) => {
  if (!color) {
    return undefined;
  }
  if (colors.includes(color)) {
    return `var(--${color})`;
  }
  return color;
};
