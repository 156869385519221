import editIcon from "../../../../assets/edit.svg";
import { Divider, Dropdown, Form } from "antd";
import { DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";
import "../../../../components/modals/invitations/styles.css";
import { useTranslation } from "react-i18next";

const CollapseExtraItem = ({ cohortId, onClick, remove, cohortName }) => {
  const items = [
    {
      key: "1",
      label: "Delete",
      icon: <DeleteOutlined />,
      onClick: () => remove(cohortId, cohortName),
    },
  ];

  return (
    <Dropdown
      menu={{
        items,
        onClick,
      }}
      trigger={["click"]}
      overlayStyle={{ width: 120 }}
    >
      <EllipsisOutlined
        style={{ cursor: "pointer", width: 30 }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </Dropdown>
  );
};

const CohortCardView = ({ cohort, toEditMode, deleteCohort, form }) => {
  const { value, description } = cohort || {};
  const { t } = useTranslation();

  return (
    <div
      className="categories-modal"
      style={{ position: "relative", bottom: 16 }}
    >
      <Divider />
      <div className="task-drawer__header pl11">
        <div />
        <div className="categories__modal-header__right">
          <CollapseExtraItem
            cohortName={cohort.value}
            cohortId={cohort.id}
            remove={deleteCohort}
          />
          <img src={editIcon} alt="edit" onClick={toEditMode} />
        </div>
      </div>
      <Form form={form}>
        <Form.Item key={"value"} name={"value"}>
          <div className="invite-user__field">
            <p className="invite-user__field__name">{t(`cohort.modal.name`)}</p>
            <p className="categories-drawer__body__title">{value}</p>
          </div>
        </Form.Item>
        <Form.Item key={"description"} name={"description"}>
          <div className="invite-user__field">
            <p className="invite-user__field__name">
              {t(`cohort.modal.description`)}
            </p>
            <p className="categories-drawer__body__description">
              {description}
            </p>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CohortCardView;
