import { useForm } from "antd/es/form/Form";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Form, Modal, message } from "antd";
import { useTranslation } from "react-i18next";
import {
  fetchUsers,
  setIsOpenProfile,
  setProfileUser,
  selectIsOpenProfile,
  selectProfileUser,
  selectProfilePlacement,
  setProfilePlacement,
} from "../../store/slices/user_management";
import { selectIsAdmin, selectUser, setUser } from "../../store/slices/user";
// import ApiUsersManagement from "../../api/user-manager";
import useNotification from "../../components/notification/notification";
import ApiUser from "../../api/user";
import { Drawer } from "../drawer/drawer";
import { DrawerHeader } from "../drawer/drawer-header";
import {
  FormAvatar,
  FormHeader,
  FormInput,
  FormInputButton,
  FormSelect,
  FormUserStatus,
} from "../form/form";
import ChangePasswordForm from "./forms/change-password-form";
import getDateByDiff from "../../common/utils/calendar/get-date-by-diff";
import getDate from "../../common/utils/calendar/get-date";
import ApiUsersManagement from "../../api/user-manager";

const DeleteModalComponent = ({
  item,
  handleOk,
  handleCancel,
  isModalOpen,
}) => {
  return (
    <Modal
      title={`${item.firstName} ${item.lastName}`}
      open={isModalOpen}
      onOk={() => {
        handleOk(item.id, item);
      }}
      onCancel={handleCancel}
      okButtonProps={{ danger: true }}
      okText="Delete"
      className="plan-page-action-modal"
    >
      <p>Are you sure you want to delete this user?</p>
    </Modal>
  );
};

const Profile = () => {
  const user = useSelector(selectProfileUser);
  const currentUser = useSelector(selectUser);
  const isAdmin = useSelector(selectIsAdmin);
  const isOpenProfile = useSelector(selectIsOpenProfile);
  const placement = useSelector(selectProfilePlacement);
  const [form] = useForm();
  const [isEdit, setEdit] = useState(false);
  const [isOpenPassword, setOpenPassword] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isDataLoaded = () => !Object.keys(user).length;
  const { hash } = useLocation();
  const navigation = useNavigate();
  const [userRole, setUserRole] = useState();
  const [newUserAvatar, setNewUserAvatar] = useState();
  const { openNotification } = useNotification();
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  // const [message, messageEl] = message.useMessage();

  const roleOptions = [
    {
      label: "Administator",
      value: "admin",
    },
    {
      label: "User",
      value: "user",
    },
  ];

  const fetchUsersList = () => {
    let status;
    switch (hash) {
      case "#disabled":
        status = "inactive";
        break;
      case "#pending":
        status = ["invited", "inviteError"];
        break;
      default:
        break;
    }

    dispatch(fetchUsers(status));
  };

  const updateUser = () => {
    const fields = form.getFieldsValue();

    if (!hasChanges) {
      setEdit(false);
      return;
    }

    const params = {};

    if (userRole) {
      fields.role = userRole;
    }

    if (newUserAvatar) {
      fields.avatar = newUserAvatar;
    }

    Object.keys(fields).forEach((key) => {
      if (fields[key]) {
        params[key] = fields[key];
      }
    });

    ApiUser.update(user.id, params)
      .then((r) => {
        setEdit(false);
        dispatch(setProfileUser(r.data.user));
        fetchUsersList();
        if (user.id === currentUser.id) {
          dispatch(setUser({ ...currentUser, ...params }));
        }
      })
      .then(() => {
        message.success(t("profile.notification.update.success"));
        setHasChanges(false);
      })
      .catch(() => {
        message.error(t("profile.notification.update.error"));
      });
  };

  const reInvite = () => {
    const { id, email } = user;
    ApiUsersManagement.resendInvite(id)
      .then(() => {
        openNotification({
          type: "success",
          message: (
            <p>{t("profile.notification.reinvite.success", { email })}</p>
          ),
        });
      })
      .catch(() => {
        openNotification({
          type: "error",
          message: <p>{t("profile.notification.reinvite.error")}</p>,
        });
      });
  };

  const isCurrentUser = () => user.id === currentUser.id;

  const isDisabledUser = () => ["blocked", "inactive"].includes(user.status);

  const shouldShowToogleStatusButton = () =>
    ["confirmed", "blocked", "inactive"].includes(user.status);

  const showReInviteButton = () =>
    ["invited", "inviteError"].includes(user.status);

  const toogleUserStatus = () => {
    const { activate, deactivate } = ApiUser;
    const isDisabled = isDisabledUser();
    const toogle = isDisabled ? activate(user.id) : deactivate(user.id);
    toogle
      .then(() => {
        openNotification({
          type: "success",
          message: (
            <p>
              {t(
                `profile.notification.toogle_user.${
                  isDisabled ? "activated" : "deactivated"
                }`,
                {
                  name: user.fullName || `${user.firstName} ${user.lastName}`,
                }
              )}
            </p>
          ),
        });
      })
      .catch(() => {
        openNotification({
          type: "error",
          message: <p>{t("profile.notification.toogle_user.error")}</p>,
        });
      })
      .finally(() => {
        fetchUsersList();
        navigation("/administration#system-users");
      });
  };

  const onAvatarChange = (avatarUrl) => {
    setNewUserAvatar(avatarUrl);
  };

  const onRoleChange = (id) => {
    setHasChanges(true);
    setUserRole(id);
  };

  const editToogle = () => {
    form.resetFields();
    setHasChanges(false);
    setEdit(!isEdit);
  };

  const openPasswordModal = () => {
    setOpenPassword(true);
  };

  const closePasswordModal = () => {
    setOpenPassword(false);
  };

  const openUserDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const closeDeleteUserModal = () => {
    setOpenDeleteModal(false);
  };

  const close = () => {
    setEdit(false);
    setHasChanges(false);
    form.resetFields();
    dispatch(setIsOpenProfile(false));
    dispatch(setProfileUser());
    dispatch(setProfilePlacement("right"));
  };

  const deleteUser = () => {
    const { id } = user;
    ApiUsersManagement.deleteUser(id)
      .then(() => {
        openNotification({
          type: "success",
          message: <p>{t("profile.notification.delete.success")}</p>,
        });

        fetchUsersList();
        close();
      })
      .catch((e) => {
        console.log(e);
        openNotification({
          type: "error",
          message: <p>{t("profile.notification.delete.error")}</p>,
        });
      });
  };

  const onFieldsChange = (v) => {
    if (!hasChanges) {
      setHasChanges(true);
    }
  };

  const shouldUpdateUserStatus = () =>
    !isCurrentUser() && isAdmin && shouldShowToogleStatusButton();

  const shouldShowDeleteReinviteButtons = () =>
    !isCurrentUser() && isAdmin && showReInviteButton();

  if (isOpenProfile && user) {
    return (
      <Drawer
        open={isOpenProfile}
        width={placement === "left" ? 600 : 800}
        onClose={close}
        placement={placement}
      >
        <DrawerHeader
          title={t("profile.title")}
          isEditing={isEdit}
          onEdit={editToogle}
          onClose={close}
          onSave={updateUser}
          onCancel={editToogle}
        />
        {isDataLoaded() ? null : (
          <Form form={form} onFieldsChange={onFieldsChange}>
            <Form.Item name="avatar" valuePropName="fileList">
              <FormAvatar
                onChange={onAvatarChange}
                value={user.avatar}
                readonly={!isEdit}
              />
            </Form.Item>
            <div style={{ paddingBottom: 24 }} />
            <Form.Item name="role" label={t("profile.form.role")}>
              <FormSelect
                options={roleOptions}
                disabled={isEdit && (isCurrentUser() || !isAdmin)}
                defaultValue={
                  roleOptions.filter((role) => role.value === user.role)[0]
                }
                onSelect={onRoleChange}
                readonly={!isEdit}
              />
            </Form.Item>
            <Form.Item name="firstName" label={t("profile.form.first_name")}>
              <FormInput
                defaultValue={user.firstName}
                maxLength={1000}
                readonly={!isEdit}
              />
            </Form.Item>
            <Form.Item name="lastName" label={t("profile.form.last_name")}>
              <FormInput
                defaultValue={user.lastName}
                maxLength={1000}
                readonly={!isEdit}
              />
            </Form.Item>
            <Form.Item name="email" label={t("profile.form.email")}>
              <FormInput
                defaultValue={user.email}
                maxLength={1000}
                readonly
                disabled={isEdit}
              />
            </Form.Item>
            <Form.Item label={t("profile.form.password")}>
              <FormInputButton
                value={"• • • • • • • • • • •"}
                onClick={openPasswordModal}
                readonly={!isEdit}
              />
            </Form.Item>
            {isEdit && (
              <>
                <div style={{ minHeight: 32 }} />
                {shouldUpdateUserStatus() ? (
                  <div style={{ display: "flex", gap: 15 }}>
                    <Button
                      key="toogleUser"
                      type="secondary"
                      style={{
                        background: "rgba(108, 108, 137, 0.08)",
                        height: 40,
                      }}
                      onClick={toogleUserStatus}
                    >
                      {t(
                        `profile.form.${
                          isDisabledUser() ? "activate_user" : "deactivate_user"
                        }`
                      )}
                    </Button>

                    {isDisabledUser() && (
                      <Button
                        key="delete"
                        type="primary"
                        danger
                        ghost
                        style={{ height: 40 }}
                        onClick={openUserDeleteModal}
                      >
                        {t("profile.form.delete")}
                      </Button>
                    )}
                  </div>
                ) : null}
              </>
            )}
            {!isEdit && (
              <>
                <FormHeader label={"Status"} />
                <Form.Item label={t("profile.form.status")}>
                  <FormUserStatus status={user.status} />
                </Form.Item>
                <Form.Item label={t("profile.form.invited")}>
                  <FormInput
                    value={getDate(user.invitedAt)}
                    readonly={!isEdit}
                    disabled={isEdit}
                  />
                </Form.Item>
                <Form.Item label={t("profile.form.last_active")}>
                  <FormInput
                    value={getDateByDiff(user.lastActivity)}
                    readonly={!isEdit}
                    disabled={isEdit}
                  />
                </Form.Item>
              </>
            )}

            {shouldShowDeleteReinviteButtons() && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginBottom: 10,
                }}
              >
                <Button
                  key="reinvite"
                  type="primary"
                  style={{ height: 40 }}
                  onClick={reInvite}
                >
                  {t("profile.form.reinvite")}
                </Button>
                <Button
                  key="delete"
                  type="primary"
                  danger
                  ghost
                  style={{ height: 40 }}
                  onClick={openUserDeleteModal}
                >
                  {t("profile.form.delete")}
                </Button>
              </div>
            )}
          </Form>
        )}
        <ChangePasswordForm
          t={t}
          isOpen={isOpenPassword}
          close={closePasswordModal}
          // errorFields={errorFields}
        />

        <DeleteModalComponent
          isModalOpen={isOpenDeleteModal}
          handleCancel={closeDeleteUserModal}
          handleOk={deleteUser}
          item={user}
        />
      </Drawer>
    );
  }

  return null;
};

export default Profile;
