import CohortCardView from "./view";
import CohortCardEdit from "./edit";
import ModalWrapper from "../../../../components/modal/modal";
import { useTranslation } from "react-i18next";

const CohortCard = ({
  cohort,
  isOpen,
  close,
  editMode,
  createCohort,
  updateCohort,
  deleteCohort,
  toEditMode,
  form,
}) => {
  const { t } = useTranslation();

  const create = () => {
    const { value, description } = form.getFieldsValue();
    console.log(form.getFieldsValue());
    if (!cohort.id) {
      createCohort({
        value,
        description,
      });
    } else {
      updateCohort(cohort.id, {
        ...cohort,
        value,
        description,
      });
    }
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      title={cohort.id ? "Cohort" : "Create cohort"}
      onOk={create}
      onCancel={close}
      okText={t(
        cohort.id ? "categories.modal.update" : "categories.modal.create"
      )}
      okButtonProps={{
        size: "large",
        style: { display: editMode ? "" : "none" },
      }}
      cancelButtonProps={{
        size: "large",
        style: {
          backgroundColor: "rgba(108, 108, 137, 0.08)",
          display: editMode ? "" : "none",
        },
      }}
    >
      {editMode ? (
        <CohortCardEdit
          cohort={cohort}
          createCohort={createCohort}
          close={close}
          updateCohort={updateCohort}
          deleteCohort={deleteCohort}
          form={form}
        />
      ) : (
        <CohortCardView
          cohort={cohort}
          close={close}
          toEditMode={toEditMode}
          deleteCohort={deleteCohort}
          form={form}
        />
      )}
    </ModalWrapper>
  );
};

export default CohortCard;
