import { Button, Form, Input } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import "./styles.css";
import { Link } from "react-router-dom";

function ResetPassView({ form, t, inProgress, resetPassword }) {
  return (
    <div className="auth">
      <div className="auth__container">
        <div className="auth__header">
          <h1>{t("login.reset")}</h1>
        </div>
        <div className="auth__body">
          <Form form={form} layout="vertical" onFinish={resetPassword}>
            <Form.Item
              label={t("login.email")}
              name="email"
              data-testid="email-label"
              rules={[
                {
                  required: true,
                  message: t("login.validation_msg.email"),
                },
                {
                  type: "email",
                  message: t("login.validation_msg.incorrect_email"),
                },
              ]}
            >
              <Input
                placeholder="email@example.com"
                size="large"
                disabled={inProgress}
                data-testid="email-input"
                maxLength={100}
              />
            </Form.Item>
            <Form.Item>
              <div className="reset-footer">
                <Link to="/login">
                  <Button
                    icon={<LeftOutlined className="reset-footer__back-icon" />}
                    size="large"
                    className="reset-footer__back-btn"
                  />
                </Link>
                <Button
                  htmlType="submit"
                  type="primary"
                  size="large"
                  style={{ marginLeft: 8 }}
                  disabled={inProgress}
                  data-testid="submit-reset-password"
                >
                  {t("login.reset")}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassView;
