import { createSlice } from "@reduxjs/toolkit";
import ApiTrails from "../../api/trail";

export const AuditScenarioTrailsSlice = createSlice({
  name: "scenarioTrails",
  initialState: {
    trails: null,
    hasMore: false,
    isLoading: false,
  },
  reducers: {
    setTrails: (state, action) => ({
      ...state,
      trails: state.trails
        ? [
            ...state.trails,
            ...action.payload.filter((p) => !state.trails?.includes(p.id)),
          ]
        : action.payload,
      hasMore: action.payload?.length && action.payload?.length === 30,
      isLoading: false,
    }),
    resetTrails: (state) => ({
      ...state,
      trails: [],
      hasMore: false,
      isLoading: false,
    }),
    setLoading: (state) => ({ ...state, isLoading: true }),
  },
});

export const { setTrails, resetTrails, setLoading } =
  AuditScenarioTrailsSlice.actions;

export const fetchScenarioTrails = (query) => (dispatch) => {
  dispatch(setLoading());
  ApiTrails.getScenarioTrails(query).then((r) => {
    dispatch(setTrails(r.data.scenarioTrails));
  });
};

export const clearScenarioTrails = () => (dispatch) => {
  dispatch(resetTrails());
};

export const selectScenarioTrails = (state) => state.scenarioTrails.trails;
export const selecthasMoreScenarioTrails = (state) =>
  state.scenarioTrails.hasMore;
export const selectScenarioTrailsLoading = (state) =>
  state.scenarioTrails.isLoading;

export default AuditScenarioTrailsSlice.reducer;
