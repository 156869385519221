import { CloseOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Checkbox, Divider, Dropdown, Input, Tag } from "antd";
import { useEffect, useState, useMemo } from "react";
import "./tags-select.css";

const TagsSelect = ({
  options = [],
  onChange,
  onCreate,
  defaultValue = [],
  placeholder = "+ Add cohort",
  selectPlaceholder,
  readonly,
  noMarginLeft,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [dropdownItems, setDropdownItems] = useState([]);
  const [search, setSearch] = useState();
  const [itemsBySearch, setItemsBySearch] = useState([]);

  useEffect(() => {
    if (JSON.stringify(selectedOptions) !== JSON.stringify(defaultValue)) {
      setSelectedOptions(defaultValue);
    }
  }, []);

  // useEffect(() => {
  //   if (onChange) {
  //     onChange(selectedOptions);
  //   }
  // }, [selectedOptions]);

  const createTag = () => {
    const newItem = { name: search, id: null, isNew: true };
    onCreate([newItem, ...selectedOptions]);
    setDropdownItems([newItem, ...dropdownItems]);
    setSelectedOptions([newItem, ...selectedOptions]);
    setSearch();
  };

  const updateSearch = (e) => {
    const searchValue = e.target.value;
    if (searchValue && searchValue.trim().length) {
      setSearch(searchValue);
    } else {
      setSearch(null);
    }
  };

  const remove = (option) => {
    const result = selectedOptions.filter(
      (item) => (item.id || item.value) !== (option.id || option.value)
    );
    setSelectedOptions(result);
    if (onChange) {
      onChange(result);
    }
  };

  const updateSelectedOptions = (option) => {
    const hasOptionInFilter = selectedOptions?.filter(
      (item) => (item.value || item.id) === (option.value || option.id)
    )[0];

    if (hasOptionInFilter) {
      remove(option);
    } else {
      setSelectedOptions([...selectedOptions, option]);
      if (onChange) {
        onChange([...selectedOptions, option]);
      }
    }
  };

  const items = () => {
    const list = () =>
      itemsBySearch.map((item) => ({
        key: item.id || item.value,
        disabled: true,
        label: (
          <div
            key={item.id || item.value}
            className="dropdown-tag-select-list-item"
            onClick={() => updateSelectedOptions(item)}
            role="presentation"
          >
            <Checkbox
              checked={
                selectedOptions.filter(
                  (val) => (val.id || val.value) === (item.id || item.value)
                )[0]
              }
            />
            <span style={{ marginLeft: 10 }}>{item.label || item.name}</span>
          </div>
        ),
      }));

    const addNewItemField = () => {
      if (
        !itemsBySearch.filter((i) => i.name === search || i.label === search)
          .length &&
        search &&
        onCreate
      ) {
        return [
          {
            key: "addNew",
            label: (
              <div style={{ display: "flex", cursor: "pointer" }}>
                <PlusOutlined className="dropdown-tag-select-add-icon" />
                <span onClick={createTag} role="presentation">
                  Add "{search}"
                </span>
              </div>
            ),
          },
        ];
      }

      return [];
    };

    return [
      {
        key: "search",
        disabled: true,
        label: (
          <div className="dropdown-tag-select-search-container">
            <div style={{ display: "flex" }}>
              <SearchOutlined />
              <Input
                bordered={false}
                value={search}
                placeholder={
                  onCreate
                    ? "Select or create tag"
                    : selectPlaceholder || "Select tag"
                }
                onChange={updateSearch}
              />
            </div>
            <Divider className="dropdown-tag-select-search-divider" />
          </div>
        ),
      },
      ...list(),
      ...addNewItemField(),
    ];
  };

  const tagsList = useMemo(
    () =>
      selectedOptions.map((item) => (
        <Tag
          key={item.id || item.value}
          className="dropdown-tag-select-tag-item"
        >
          <div className="dropdown-tag-select-tag-container">
            <span>{item.name || item.label}</span>
            {readonly ? null : (
              <CloseOutlined
                onClick={() => remove(item)}
                className="dropdown-tag-select-tag-close"
              />
            )}
          </div>
        </Tag>
      )),
    [selectedOptions, readonly]
  );

  useEffect(() => {
    if (!dropdownItems.length) {
      return;
    }

    setItemsBySearch(
      search
        ? dropdownItems.filter((option) =>
            (option.label || option.name)
              ?.toLowerCase()
              .includes(search?.toLowerCase())
          )
        : dropdownItems
    );
  }, [search, dropdownItems]);

  useEffect(() => {
    setSearch();
    setDropdownItems(
      options.filter(
        (option, index, self) =>
          option.label ||
          option.name ||
          index ===
            self.findIndex(
              (p) => p.name === option.name || p.label === option.label
            )
      )
    );
  }, [options]);

  return (
    <div className="dropdown-tag-select-container">
      {tagsList}
      {!readonly ? (
        <Dropdown
          menu={{
            items: items(),
          }}
          trigger={["click"]}
          overlayClassName="dropdown-tag-select"
        >
          <span
            className="dropdown-tag-select-placeholder"
            style={{ marginLeft: noMarginLeft ? 3 : 12 }}
          >
            {placeholder}
          </span>
        </Dropdown>
      ) : null}
    </div>
  );
};

export default TagsSelect;
