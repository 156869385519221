import { Divider, Form, Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../styles.css";
import InviteContent from "../components/invite-content";
import ModalWrapper from "../../../modal/modal";
import useNotification from "../../../notification/notification";
import ApiSubjectsManagement from "../../../../api/subjects-manager";
import { LoadingOutlined } from "@ant-design/icons";

const InviteSubjectModal = ({
  isOpen,
  close,
  fetchEnrollInviteUserTemplate,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { openNotification } = useNotification();
  const [inProgress, setProgress] = useState(false);
  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const [contentFields, setContentFields] = useState([]);
  const [selectsValues, setSelectsValues] = useState({});

  const onModalSelectChange = (id, value) => {
    setSelectsValues({ ...selectsValues, [id]: value });
  };

  const onClose = () => {
    close();
    form.resetFields();
  };

  const inviteSubject = () => {
    const newUser = form.getFieldsValue();

    setProgress(true);

    let newSubject = {
      email: newUser.email,
    };

    delete newUser.email;

    const defaultSelectsValues = contentFields
      .filter((f) => f.type === "select")
      .reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: item.options.filter((o) => o.default)[0]?.id,
        }),
        {}
      );

    newSubject = {
      ...newSubject,

      customFields: {
        ...newUser,
        ...defaultSelectsValues,
        ...selectsValues,
      },
    };

    ApiSubjectsManagement.invite(newSubject)
      .then(() => {
        openNotification({
          type: "success",
          message: (
            <p>
              {t("invite_subject.notification.success", {
                email: newSubject.email,
              })}
            </p>
          ),
        });
      })
      .catch((e) => {
        openNotification({
          type: "error",
          message: (
            <p>
              {t(
                `invite_subject.notification.${
                  e?.response?.data?.error?.code || "error"
                }`
              )}
            </p>
          ),
        });
      })
      .finally(() => {
        setProgress(false);
        onClose();
      });
  };

  useEffect(() => {
    setIsLoadingContent(true);

    if (fetchEnrollInviteUserTemplate) {
      fetchEnrollInviteUserTemplate()
        .then((r) => {
          setContentFields([
            {
              id: "email",
              label: "Email",
              placeholder: t("invite_user.placeholders.custom_email"),
              type: "input",
            },
            ...r.data.enrollmentFields,
          ]);
        })
        .finally(() => {
          setIsLoadingContent(false);
        });
    }

    setIsLoadingContent(false);
  }, [fetchEnrollInviteUserTemplate]);

  return (
    <ModalWrapper
      isOpen={isOpen}
      title={t("invite_subject.title")}
      onOk={() => inviteSubject()}
      onCancel={onClose}
      okText={t("invite_subject.invite")}
      okButtonProps={{
        size: "large",
      }}
      cancelButtonProps={{
        size: "large",
        style: { backgroundColor: "rgba(108, 108, 137, 0.08)" },
      }}
      confirmLoading={inProgress}
    >
      <Divider />
      {isLoadingContent ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      ) : (
        <Form form={form}>
          <InviteContent
            fields={contentFields}
            onSelectChange={onModalSelectChange}
          />
        </Form>
      )}
      <Divider />
    </ModalWrapper>
  );
};

export default InviteSubjectModal;
