import { Divider, Form, Input } from "antd";
import "../styles.css";
import TextArea from "antd/es/input/TextArea";
import "../../../../components/modals/invitations/styles.css";
import { useTranslation } from "react-i18next";

const TaskCardEdit = ({ task, form }) => {
  const { t } = useTranslation();
  return (
    <div
      className="categories-modal"
      style={{ position: "relative", bottom: 16 }}
    >
      <Divider />
      <Form form={form}>
        <Form.Item key={"name"} name={"name"}>
          <div className="invite-user__field">
            <p className="invite-user__field__name">
              {t(`categories.modal.name`)}
            </p>
            <Input
              placeholder={t(`categories.placeholders.category_name`)}
              bordered={false}
              className="categories-drawer__body__title"
              // onChange={({ target }) => updateTitle(target.value)}
              defaultValue={task?.name}
              maxLength={256}
            />
          </div>
        </Form.Item>
        <Form.Item key={"description"} name={"description"}>
          <div className="invite-user__field">
            <p className="invite-user__field__name">
              {t(`categories.modal.description`)}
            </p>
            <TextArea
              placeholder={t(`categories.placeholders.category_description`)}
              bordered={false}
              className="categories-drawer__body__description"
              // onChange={({ target }) => updateDescription(target.value)}
              defaultValue={task?.description}
              maxLength={1000}
            />
          </div>
        </Form.Item>
      </Form>
      <Divider style={{ margin: 0 }} />
    </div>
  );
};

export default TaskCardEdit;
