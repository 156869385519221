import axios from "axios";
import toQueryString from "../common/utils/to-query-string";
import downloadBlob from "../common/utils/download-blob";

const invite = (newUser) => axios.post("api/v1/auth/signup/subject", newUser);

const getSubjects = (query) =>
  axios.get(toQueryString("api/v1/subjects?", query));

const getSubject = (id) => axios.get(`/api/v1/subjects/${id}`);

const resendInvite = (id) => axios.post(`/api/v1/subjects/${id}/invite`);

const deleteSubject = (subjectId) =>
  axios.post(`/api/v1/subjects/${subjectId}/delete`);

const getSubjectSurveys = (subjectId) =>
  axios.get(`/api/v1/subject-surveys/subject/${subjectId}`);

const updateSubjectData = (subjectData) =>
  axios.put("/api/v1/enrollment-responses", subjectData);

const updateSurvey = (id, response) =>
  axios.put(`/api/v1/subject-surveys/${id}`, { response });

const getSubjectTasks = (id, params) =>
  axios.get(toQueryString(`/api/v1/task-managements/subject/${id}?`, params));

const getHealth = (subjectId, params) =>
  axios.get(
    toQueryString(`/api/v1/subject-healths/subjects/${subjectId}?`, params)
  );

const exportHealth = (subjectId, params) =>
  axios
    .get(
      toQueryString(`/api/v1/subject-healths/subjects/${subjectId}?`, params)
    )
    .then((resp) => {
      const { content, filename } = resp.data;
      downloadBlob(content, filename);
    });

const ApiSubjectsManagement = {
  invite,
  getSubjects,
  getSubject,
  resendInvite,
  deleteSubject,
  getSubjectSurveys,
  updateSubjectData,
  updateSurvey,
  getSubjectTasks,
  getHealth,
  exportHealth,
};

export default ApiSubjectsManagement;
