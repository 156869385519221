import Header from "../../header/header";
import { useTranslation } from "react-i18next";

const HeaderLayout = ({ title, search, create, isBold, filters, period }) => {
  const { t } = useTranslation();

  return (
    <Header
      title={title}
      search={
        search && {
          value: search.value,
          onChange: search.onHandleChange,
          placeholder: t("assets.btn.search"),
        }
      }
      create={create}
      isBold={isBold}
      filters={filters}
      period={period}
    />
  );
};

export default HeaderLayout;
