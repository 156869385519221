export const nodeDefinitions = {
  "message:info": {
    handles: { b: true, t: true },
    rootLabelField: "message",
    isDraggable: true,
    validationSchema: {
      type: "object",
      properties: {
        message: {
          type: "string",
          minLength: 1,
          errorMessage: { minLength: "Message is required" },
        },
      },
      required: ["message"],
    },
    nodeInfoContent: [
      {
        label: "Message",
        placeholder: "Add message",
        defaultValue: "",
        fieldName: "message",
        fieldType: "textarea-with-alternatives",
      },
    ],
  },
  "message:input": {
    handles: { b: true, t: true },
    rootLabelField: "message",
    isDraggable: true,
    validationSchema: {
      type: "object",
      properties: {
        message: {
          type: "string",
          minLength: 1,
          errorMessage: { minLength: "Message is required" },
        },
        placeholder: {
          type: "string",
          maxLength: 255,
          errorMessage: { maxLength: "Placeholder max length exceeded (255)" },
        },
      },
      required: ["message"],
    },
    nodeInfoContent: [
      {
        label: "Message",
        placeholder: "Add message",
        defaultValue: "",
        fieldName: "message",
        fieldType: "textarea-with-alternatives",
      },
      {
        label: "Placeholder",
        fieldName: "placeholder",
        placeholder: "Add placeholder",
        fieldType: "input",
        defaultValue: "Enter response...",
      },
    ],
  },
  "message:multi": {
    handles: { b: true, t: true },
    rootLabelField: "message",
    validationSchema: {
      type: "object",
      properties: {
        label: {
          type: "string",
        },
        message: {
          type: "string",
          minLength: 1,
          errorMessage: { minLength: "Message is required" },
        },
        type: {
          type: "string",
          enum: ["checkbox", "chip"],
          errorMessage: { enum: "Type is required" },
        },
        response: {
          type: "array",
          minItems: 1,
          errorMessage: { minItems: "Response is required" },
        },
      },
      required: ["message", "response"],
    },
    nodeInfoContent: [
      {
        label: "Label",
        placeholder: "Add label",
        defaultValue: "Select...",
        fieldName: "label",
        fieldType: "input",
      },
      {
        label: "Message",
        placeholder: "Add message",
        defaultValue: "",
        fieldName: "message",
        fieldType: "textarea-with-alternatives",
      },
      {
        label: "Type",
        description: "This is type of multiselect item",
        placeholder: "Select type...",
        defaultValue: "checkbox",
        fieldName: "itemType",
        fieldType: "select",
        options: [
          { label: "Checkbox", value: "checkbox" },
          { label: "Chip", value: "chip" },
        ],
      },
      {
        label: "Response options",
        fieldName: "response",
        fieldType: "multi-options",
      },
    ],
  },
  'message:suggestions': {
    handles: { b: true, t: true },
    rootLabelField: 'title',
    isDraggable: true,
    cardWidth: '450',
    dynamicValidationParams: {
      ids: ['properties.articles.properties.ids.items.enum'],
      categories: ['properties.articles.properties.filters.properties.categories.items.enum'],
      tags: ['properties.articles.properties.filters.properties.tags.items.enum'],
    },
    validationSchema: {
      "type": "object",
      "properties": {
        "articles": {
          "type": "object",
          "properties": {
            "type": {
              "type": "string",
              "enum": ["ids", "filters"]
            },
            "ids": {
              "type": "array",
              "items": {
                "type": "string",
                "errorMessage": { enum: 'The associated object was deleted' }
              },
              "errorMessage": { enum: 'The associated object was deleted' }
            },
            "filters": {
              "type": "object",
              "properties": {
                "categories": {
                  "type": "array",
                  "items": {
                    "type": "string",
                  },
                  "errorMessage": { enum: 'The associated object was deleted' }
                },
                "tags": {
                  "type": "array",
                  "items": {
                    "type": "string"
                  },
                  "errorMessage": { enum: 'The associated object was deleted' }
                },
                "maxNumber": {
                  "type": "number",
                  "minimum": 1,
                  "maximum": 10,
                  "errorMessage": {
                    type: 'Max number must be a number' ,
                    minimum: 'Max number must be greater than 0',
                    maximum: 'Max number must be less than 11'
                  }
                }
              }
            }
          },
          "required": ["type"],
          "allOf": [
            {
              "if": {
                "properties": { "type": { "const": "ids" } }
              },
              "then": {
                "required": ["ids"],
                "properties": {
                  "ids": {
                    "minItems": 1,
                    "type": "array",
                    "errorMessage": { minItems: "At least one Article is required", enum: "The associated object was deleted" }
                  }
                },
              },
              "errorMessage": "At least one Article is required"
            },
            // INFO: prevent empty categories AND tags
            // {
            //   "if": {
            //     "properties": { "type": { "const": "filters" } }
            //   },
            //   "then": {
            //     "required": ["filters"],
            //     "properties": {
            //       "filters": {
            //         "anyOf": [
            //           { "required": ["categories"], "properties": { "categories": { "minItems": 1 } } },
            //           { "required": ["tags"], "properties": { "tags": { "minItems": 1 } } }
            //         ]
            //       }
            //     }
            //   }
            // }
          ]
        }
      },
    },
    nodeInfoContent: [
      {
        label: 'Articles',
        fieldName: 'articles',
        fieldType: 'articles',
        showGenericValidation: false,
        defaultValue: {
          type: 'filters',
          ids: [],
          filters: {
            categories: [],
            tags: [],
            maxNumber: 5
          }
        }
      },
    ]
  },
  "message:date": {
    handles: { b: true, t: true },
    rootLabelField: "message",
    isDraggable: true,
    validationSchema: {
      type: "object",
      properties: {
        message: {
          type: "string",
          minLength: 1,
          errorMessage: { minLength: "Message is required" },
        },
        format: {
          type: "string",
          enum: ["DD MMM YYYY", "DD MMM YYYY, HH:mm"],
          errorMessage: { enum: "Type is required" },
        },
        placeholder: {
          type: "string",
          maxLength: 255,
          errorMessage: { maxLength: "Placeholder max length exceeded (255)" },
        },
      },
      required: ["message", "format"],
    },
    nodeInfoContent: [
      {
        label: "Message",
        placeholder: "Add message",
        defaultValue: "",
        fieldName: "message",
        fieldType: "textarea-with-alternatives",
      },
      {
        label: "Format",
        description: "This is format of date picker",
        placeholder: "Select format...",
        defaultValue: "DD MMM YYYY",
        fieldName: "format",
        fieldType: "select",
        options: [
          { label: "DD MMM YYYY", value: "DD MMM YYYY" },
          { label: "DD MMM YYYY, HH:mm", value: "DD MMM YYYY, HH:mm" },
        ],
      },
      {
        label: "Placeholder",
        fieldName: "placeholder",
        placeholder: "Add placeholder",
        fieldType: "input",
        defaultValue: "Select date...",
      },
    ],
  },
  'message:picker': {
    handles: { b: true, t: true },
    rootLabelField: 'message',
    isDraggable: true,
    validationSchema: {
      type: 'object',
      properties: {
        type: {
          type: 'string',
          enum: ['custom', 'weight', 'height'],
          errorMessage: { enum: 'Type is required' }
        },
        message: {
          type: 'string',
          minLength: 1,
          errorMessage: { minLength: 'Message is required' }
        },
        placeholder: {
          type: 'string',
          minLength: 1,
          errorMessage: { minLength: 'Placeholder is required' }
        },
        decimal: {
          type: 'boolean',
        },
        min: {
          anyOf: [
            {
              type: 'string',
              pattern: '^-?\\d+(\\.\\d+)?$',
            },
            {
              type: 'string',
              maxLength: 0,
            }
          ]
        },
        max: {
          anyOf: [
            {
              type: 'string',
              pattern: '^-?\\d+(\\.\\d+)?$',
            },
            {
                type: 'string',
                maxLength: 0,
            }
          ]
        },
        step: {
          anyOf: [
            {
              type: 'string',
              pattern: '^-?\\d+(\\.\\d+)?$',
            },
            {
                type: 'string',
                maxLength: 0,
            }
          ]
        },
        suffix: {
          type: 'string',
        },
      },
      required: ['type', 'message', 'placeholder'],
      allOf: [
        {
          if: {
            properties: { type: { enum: ['weight', 'height'] } }
          },
          then: {
            required: ['min', 'max', 'step', 'suffix']
          }
        }
      ]
    },
    nodeInfoContent: [
      {
        label: 'Label',
        placeholder: 'Add label',
        defaultValue: 'Select...',
        fieldName: 'label',
        fieldType: 'input',
      },
      {
        label: 'Type',
        description: 'This is type of picker',
        placeholder: 'Select type...',
        defaultValue: 'custom',
        fieldName: 'type',
        fieldType: 'select',
        options: [
          { label: 'Custom', value: 'custom' },
          { label: 'Weight', value: 'weight' },
          { label: 'Height', value: 'height' },
        ]
      },
      {
        label: 'Message',
        placeholder: 'Add message',
        defaultValue: '',
        fieldName: 'message',
        fieldType: 'textarea-with-alternatives',
      },
      {
        label: 'Placeholder',
        placeholder: 'Add placeholder',
        defaultValue: '',
        fieldName: 'placeholder',
        fieldType: 'input',
      },
      {
        label: 'Decimal',
        defaultValue: false,
        fieldName: 'decimal',
        fieldType: 'switch',
        predicate: [{ key: 'type', value: 'custom' }],
      },
      {
        label: 'Minimum value',
        placeholder: 'Add minimum value',
        defaultValue: '',
        fieldName: 'min',
        fieldType: 'input',
        predicate: [{ key: 'type', value: 'custom' }],
      },
      {
        label: 'Maximum value',
        placeholder: 'Add maximum value',
        defaultValue: '',
        fieldName: 'max',
        fieldType: 'input',
        predicate: [{ key: 'type', value: 'custom' }],
      },
      {
        label: 'Step value',
        placeholder: 'Add step value',
        defaultValue: '',
        fieldName: 'step',
        fieldType: 'input',
        predicate: [{ key: 'type', value: 'custom' }],
      },
      {
        label: 'Suffix',
        placeholder: 'Add suffix...',
        defaultValue: '',
        fieldName: 'suffix',
        fieldType: 'combobox-with-single-value-and-custom-options',
        options: [
          { label: 'mg', value: 'mg' },
          { label: 'g', value: 'g' },
          { label: 'cups', value: 'cups' },
          { label: 'kg', value: 'kg' },
          { label: 'ml', value: 'ml' },
          { label: '%', value: '%' },
        ],
        predicate: [{ key: 'type', value: 'custom' }],
      }
    ]
  },
  "message:*": {
    handles: { b: true, t: true },
    // bgColor: 'pink',
    rootLabelField: "message",
    validationSchema: {
      type: "object",
      properties: {
        message: {
          type: "string",
          minLength: 1,
          errorMessage: { minLength: "Message is required" },
        },
        response: {
          type: "array",
          minItems: 1,
          errorMessage: { minItems: "Response is required" },
        },
      },
      required: ["message", "response"],
    },
    nodeInfoContent: [
      {
        label: "Message",
        placeholder: "Add message",
        defaultValue: "",
        fieldName: "message",
        fieldType: "textarea-with-alternatives",
      },
      {
        label: "Response options",
        fieldName: "response",
        fieldType: "multi-options",
      },
    ],
  },
  "timer:wait": {
    handles: { b: true, t: true },
    rootLabelField: "timeout",
    cardType: "ghost",
    labelDescription: "Timeout",
    validationSchema: {
      type: "object",
      properties: {
        timeout: {
          type: "string",
          pattern: '^(?:[01]\\d|2[0-3]):[0-5]\\d:[0-5]\\d$',
          minLength: 1,
          errorMessage: {
            minLength: "Message is required",
            pattern: "Wrong timeout format",
          },
        },
      },
      required: ["timeout"],
    },
    nodeInfoContent: [
      {
        label: "Timeout",
        placeholder: "hh:mm:ss",
        defaultValue: "",
        fieldName: "timeout",
        fieldType: "input",
      },
    ],
  },
  "task:*": {
    handles: { b: true, t: true },
    rootLabelField: "nodeType",
    isDraggable: true,
    dynamicValidationParams: {
      tasks: ['properties.nodeType.enum']
    },
    validationSchema: {
      type: "object",
      properties: {
        nodeType: {
          type: "string",
          errorMessage: { enum: 'The associated object was deleted' }
        },
      },
      required: [],
    },
    nodeInfoContent: [
      {
        label: "Activity type",
        placeholder: "Select activity type...",
        fieldName: "nodeType",
        fieldType: "select",
        options: [
          // { label: "Nourishment", value: "task:nourishment" },
          // { label: "Run", value: "task:run" },
          // { label: "Medication", value: "task:medication" },
          // { label: "Yoga", value: "task:yoga" },
        ],
      },
    ],
  },
  'fit:facts': {
    handles: { b: true, t: true },
    rootLabelField: 'title',
    isDraggable: true,
    cardWidth: '450',
    dynamicValidationParams: {
      ids: ['properties.facts.properties.ids.items.enum'],
      categories: ['properties.facts.properties.filters.properties.categories.items.enum'],
      tags: ['properties.facts.properties.filters.properties.tags.items.enum'],
    },
    validationSchema: {
      type: 'object',
      properties: {
        facts: {
          type: 'object',
          properties: {
            type: {
              type: 'string',
              enum: ['ids', 'filters']
            },
            ids: {
              type: 'array',
              items: {
                type: 'string',
                errorMessage: { enum: 'The associated object was deleted' }
              },
              errorMessage: { enum: 'The associated object was deleted' }
            },
            filters: {
              type: 'object',
              properties: {
                categories: {
                  type: 'array',
                  items: {
                    type: 'string'
                  },
                  errorMessage: { enum: 'The associated object was deleted' }
                },
                tags: {
                  type: 'array',
                  items: {
                    type: 'string'
                  },
                  errorMessage: { enum: 'The associated object was deleted' }
                }
              }
            }
          },
          required: ['type'],
          allOf: [
            {
              if: {
                properties: {
                  type: {
                    const: 'ids'
                  }
                }
              },
              then: {
                required: ['ids'],
                properties: {
                  ids: {
                    minItems: 1,
                    type: "array",
                    errorMessage: { minItems: "At least one FitFact is required", enum: "The associated object was deleted" }
                  }
                },
              },
              errorMessage: 'At least one FitFact is required'
            },
          ]
        }
      },
    },
    nodeInfoContent: [
      {
        label: 'Facts',
        fieldName: 'facts',
        fieldType: 'facts',
        showGenericValidation: false,
        defaultValue: {
          type: 'filters',
          ids: [],
          filters: {
            categories: [],
            tags: [],
          }
        }
      },
    ]
  },
  'scenario:picker': {
    handles: { b: true, t: true },
    rootLabelField: 'selectedScenario.label',
    isDraggable: true,
    dynamicValidationParams: {
      scenarioValue: ['properties.selectedScenario.properties.value.enum'],
    },
    validationSchema: {
      type: 'object',
      properties: {
        selectedScenario: {
          type: 'object',
          properties: {
            label: {
              type: 'string',
            },
            value: {
              type: 'string',
              errorMessage: { enum: 'The associated object was deleted' }
            },
          },
          errorMessage: 'Scenario is required'
        },
      },
      required: ['message'],
    },
    nodeInfoContent: [
      {
        label: 'Select scenario',
        placeholder: '',
        defaultValue: '',
        fieldName: 'selectedScenario',
        fieldType: 'scenario-picker',
      },
    ]
  },
};
