import axios from "axios";

const getCategories = () => axios.get("api/v1/categories");

const addCategory = (category) => axios.post("api/v1/categories", category);

const updateCategory = (id, category) =>
  axios.put(`api/v1/categories/${id}`, category);

const deleteCategory = (id) => axios.delete(`api/v1/categories/${id}`);

const ApiCategoryManagement = {
  getCategories,
  addCategory,
  updateCategory,
  deleteCategory,
};

export default ApiCategoryManagement;
