import moment from "moment";
import { Drawer, Input } from "antd";
import crossIcon from "../../../../assets/cross.svg";
import UserActivityStatus from "../../../../components/user-activity-status/user-activity-status";
import encodeSvgWithColor from "../../../../common/utils/encodeSvgWithColor";

const Quiz = ({ items = [], onChange, isEdit }) => {
  const view = items.map(({ text, questionText, id }) => (
    <div key={id} className="survey__drawer__form-item">
      <p className="survey__drawer__form-item-label w250">
        {questionText || "N/A"}
      </p>
      <p>{text || "N/A"}</p>
    </div>
  ));

  const edit = items.map(({ text, questionText, id }) => (
    <div key={id} className="survey__drawer__form-item">
      <p className="survey__drawer__form-item-label w250">
        {questionText || "N/A"}
      </p>
      <Input defaultValue={text} onChange={onChange} maxLength={1000} />
    </div>
  ));

  return isEdit ? edit : view;
};

const TaskDrawer = ({ isOpenDrawer, close, task }) => {
  // const { t } = useTranslation();
  // const [isEdit, setEdit] = useState(false);
  // const { openNotification } = useNotification();

  // const [changedFields, setChangedFields] = useState([]);

  // const onChange = (responseId, text) => {
  //   const hasId = changedFields.filter((c) => c.responseId === responseId)[0];
  //   if (hasId) {
  //     setChangedFields(
  //       changedFields.map((field) =>
  //         field.responseId === responseId ? { ...field, text } : field
  //       )
  //     );
  //     return;
  //   }

  //   setChangedFields([...changedFields, { responseId, text }]);
  // };

  // const toogleEdit = () => {
  //   setEdit(!isEdit);
  // };

  // const updateTaskAnswer = () => {
  //   return;
  // };

  // const headerQuizButtons = useMemo(() => {
  //   if (isEdit) {
  //     return (
  //       <>
  //         <Button
  //           style={{
  //             height: 40,
  //             background: "rgba(108, 108, 137, 0.08)",
  //           }}
  //           onClick={toogleEdit}
  //         >
  //           {t("profile.btns.cancel")}
  //         </Button>
  //         <Button
  //           type="primary"
  //           style={{ marginLeft: 8, height: 40 }}
  //           onClick={updateTaskAnswer}
  //         >
  //           {t("profile.btns.save")}
  //         </Button>
  //       </>
  //     );
  //   }

  //   return (
  //     <>
  //       <img
  //         src={editIcon}
  //         alt="edit"
  //         role="presentation"
  //         onClick={toogleEdit}
  //         style={{ width: 38, marginTop: 2 }}
  //       />
  //       <img src={crossIcon} alt="close" role="presentation" onClick={close} />
  //     </>
  //   );
  // }, [isEdit, changedFields, task]);

  const { response, plannedAt, completedAt, status } = task || {};
  const { backgroundColor, avatar, avatarColor, title, description } =
    task?.WeeklySchedule?.DailyTask || {};

  return (
    <Drawer open={isOpenDrawer} width={600} closable={false} onClose={close}>
      <div className="task-drawer__header">
        {avatar ? (
          <div
            className="icon-wrapper"
            style={{
              backgroundColor,
            }}
          >
            <img src={encodeSvgWithColor(avatar, avatarColor)} alt="avatar" />
          </div>
        ) : (
          <div />
        )}
        <div className="task-drawer__header__right">
          <UserActivityStatus userStatus={status || "PENDING"} />
          <img src={crossIcon} alt="close" onClick={close} />
        </div>
      </div>
      <div>
        <p className="task-drawer__title">{title}</p>
        <p className="task-drawer__description">{description}</p>
        <div className="task-drawer__history-item-container">
          <p className="task-drawer__history-item__title">Due date</p>
          <p className="task-drawer__history-item__result">
            {moment(plannedAt).format("DD MMM YYYY")}
          </p>
        </div>
        {completedAt && (
          <div className="task-drawer__history-item-container">
            <div>
              <div className="border-bottom-1">
                <p className="task-drawer__history-item__title">Completed</p>
                <p className="task-drawer__history-item__result  mb10">
                  {moment(completedAt).utc().format("DD MMM YYYY, HH:mm")}, UTC
                </p>
              </div>
              {/* {headerQuizButtons} */}
            </div>
            <div>
              <Quiz items={response} />
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default TaskDrawer;
