const IconDelete = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.50414 2.46191C5.50414 2.10293 5.79515 1.81191 6.15414 1.81191H9.84612C10.2051 1.81191 10.4961 2.10293 10.4961 2.46191V4.0271H12.5381C12.8971 4.0271 13.1881 4.31812 13.1881 4.6771C13.1881 5.03609 12.8971 5.3271 12.5381 5.3271H3.46216C3.10317 5.3271 2.81216 5.03609 2.81216 4.6771C2.81216 4.31812 3.10317 4.0271 3.46216 4.0271H5.50414V2.46191ZM9.19612 3.11191V4.0271H6.80414V3.11191H9.19612ZM12.7086 6.95183C12.7415 6.59436 12.4783 6.27791 12.1208 6.24502C11.7634 6.21214 11.4469 6.47527 11.414 6.83275L10.9698 11.6615C10.9059 12.3563 10.3232 12.8879 9.62552 12.8879H6.37453C5.67684 12.8879 5.09408 12.3562 5.0302 11.6615L4.58622 6.83278C4.55335 6.4753 4.23691 6.21215 3.87944 6.24502C3.52196 6.27789 3.25881 6.59433 3.29168 6.9518L3.73566 11.7805C3.86106 13.1443 5.00499 14.1879 6.37453 14.1879H9.62552C10.995 14.1879 12.1389 13.1443 12.2644 11.7806L12.7086 6.95183Z"
      fill="#6C6C89"
    />
  </svg>
);

export default IconDelete;
