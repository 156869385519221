import axios from "axios";
import toQueryString from "../common/utils/to-query-string";

const getScores = (filter) =>
  axios.get(
    toQueryString("api/v1/subject-scores?", {
      ...filter,
      period: "day",
    })
  );

const filterField = () => axios.get("api/v1/subject-scores/filters");

const ApiSubjectsDashboard = {
  getScores,
  filterField,
};

export default ApiSubjectsDashboard;
