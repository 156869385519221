import axios from "axios";

const update = (userId, params) =>
  axios.put(`/api/v1/subjects/${userId}`, params);

const activate = (userId, params) =>
  axios.post(`/api/v1/subjects/${userId}/activate`, params);

const deactivate = (userId, params = {}) =>
  axios.post(`/api/v1/subjects/${userId}/deactivate`, params);

const reinvite = (subjectId) =>
  axios.post(`/api/v1/subjects/${subjectId}/invite`);

const ApiSubject = {
  update,
  activate,
  deactivate,
  reinvite,
};

export default ApiSubject;
