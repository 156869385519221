import { memo } from "react";

const IconArrowDown = memo(() => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.21967 5.96967C2.51256 5.67678 2.98744 5.67678 3.28033 5.96967L7.75 10.4393L12.2197 5.96967C12.5126 5.67678 12.9874 5.67678 13.2803 5.96967C13.5732 6.26256 13.5732 6.73744 13.2803 7.03033L8.28033 12.0303C7.98744 12.3232 7.51256 12.3232 7.21967 12.0303L2.21967 7.03033C1.92678 6.73744 1.92678 6.26256 2.21967 5.96967Z"
        fill="#151515"
      />
    </svg>
  );
});

export default IconArrowDown;
