import { createSlice } from "@reduxjs/toolkit";
import ApiTrails from "../../api/trail";

export const AuditSubjectTrailsSlice = createSlice({
  name: "subjectTrails",
  initialState: {
    trails: null,
    hasMore: false,
    isLoading: false,
  },
  reducers: {
    setTrails: (state, action) => ({
      ...state,
      trails: state.trails
        ? [
            ...state.trails,
            ...action.payload.filter((p) => !state.trails?.includes(p.id)),
          ]
        : action.payload,
      hasMore: action.payload?.length && action.payload?.length === 30,
      isLoading: false,
    }),
    resetTrails: (state) => ({
      ...state,
      trails: [],
      hasMore: false,
      isLoading: false,
    }),
    setLoading: (state) => ({ ...state, isLoading: true }),
  },
});

export const { setTrails, resetTrails, setLoading } =
  AuditSubjectTrailsSlice.actions;

export const fetchSubjectTrails = (query) => (dispatch) => {
  dispatch(setLoading());
  ApiTrails.getSubjectTrails(query).then((r) => {
    dispatch(setTrails(r.data.trails));
  });
};

export const clearSubjectTrails = () => (dispatch) => {
  dispatch(resetTrails());
};

export const selectSubjectTrails = (state) => state.subjectTrails.trails;
export const selecthasMoreSubjectTrails = (state) =>
  state.subjectTrails.hasMore;
export const selectSubjectTrailsLoading = (state) =>
  state.subjectTrails.isLoading;

export default AuditSubjectTrailsSlice.reducer;
