import moment from "moment";
import { useTranslation } from "react-i18next";
import UserActivityStatus from "../../../../components/user-activity-status/user-activity-status";
import { Dropdown, Modal } from "antd";
import {
  DeleteOutlined,
  EllipsisOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import InfinityTable from "../../../../components/infinity-table/infinity-table";

const DeleteModalComponent = ({
  plan,
  handleOk,
  handleCancel,
  isModalOpen,
}) => {
  return (
    <Modal
      title={plan?.title}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ danger: true }}
      className="plan-page-action-modal"
    >
      <p>Are you sure you want to delete plan?</p>
    </Modal>
  );
};

const DropdownActionItem = ({ remove, duplicate }) => {
  const items = [
    {
      key: "1",
      label: "Duplicate",
      icon: <CopyOutlined />,
      onClick: () => duplicate(),
    },
    {
      key: "2",
      label: "Delete",
      icon: <DeleteOutlined />,
      onClick: () => remove(),
    },
  ];

  return (
    <Dropdown
      menu={{
        items,
        onClick: (e) => {
          e.domEvent.stopPropagation();
        },
      }}
      trigger={["click"]}
      overlayStyle={{ width: 120 }}
    >
      <EllipsisOutlined
        style={{ cursor: "pointer", width: 30 }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </Dropdown>
  );
};

const PlanTable = ({ data, onHandleRowClick, removePlan, duplicatePlan }) => {
  const [isModalOpen, setModalOpen] = useState();
  const [item, setItem] = useState();

  const { t } = useTranslation();

  const handleOpenDeleteModal = (plan) => {
    setModalOpen(true);
    setItem(plan);
  };

  const handleCloseDeleteModal = () => {
    setModalOpen(false);
    setItem();
  };

  const handleOkPress = (id, planTitle) => {
    handleCloseDeleteModal();
    removePlan(id, planTitle);
  };

  const handleDuplicate = (id) => {
    duplicatePlan(id);
  };

  const columns = [
    {
      title: t("plan_management.table.name"),
      dataIndex: "title",
      render: (_, plan) => {
        return (
          <div className="tasks-table__name-container">
            <span style={{ fontWeight: 700 }}>{plan.title}</span>
          </div>
        );
      },
      key: "title",
      width: "20%",
    },
    {
      title: t("plan_management.table.cohort"),
      dataIndex: "cohort",
      render: (_, plan) => (
        <span>
          {(plan.activeGroups || []).reduce(
            (acc, cohort) =>
              `${acc}${acc.length ? "," : ""} ${
                cohort.EnrollmentFieldOption.value
              }`,
            ""
          ) || "N/A"}
        </span>
      ),
      key: "cohort",
      width: "20%",
    },
    {
      title: t("plan_management.table.status"),
      dataIndex: "status",
      render: (_, plan) => <UserActivityStatus userStatus={plan.status} />,
      key: "points",
      width: "20%",
    },
    {
      title: t("task_management.table.last_modified"),
      dataIndex: "updatedAt",
      render: (_, plan) => (
        <span>
          {`${moment(plan.updatedAt)
            .utc()
            .format("DD MMM YYYY, HH:mm")}, UTC` || "N/A"}
        </span>
      ),
      key: "updatedAt",
      width: "20%",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, plan) => (
        <DropdownActionItem
          planId={plan.id}
          remove={() => handleOpenDeleteModal(plan)}
          duplicate={() => handleDuplicate(plan.id)}
        />
      ),
      key: "action",
      width: "10%",
    },
  ];

  return (
    <>
      <DeleteModalComponent
        plan={item}
        handleOk={() => handleOkPress(item.id, item.title)}
        handleCancel={handleCloseDeleteModal}
        isModalOpen={isModalOpen}
      />
      <InfinityTable
        data={data}
        fetchData={() => console.log("fetchData")}
        hasMore={false}
        tableColumns={columns}
        isLoading={false}
        onHandleRowClick={onHandleRowClick}
      />
      {/*<BlockComponent>*/}
      {/*  <TableComponent*/}
      {/*    columns={columns}*/}
      {/*    data={data}*/}
      {/*    onHandleRowClick={onHandleRowClick}*/}
      {/*  />*/}
      {/*</BlockComponent>*/}
    </>
  );
};

export default PlanTable;
