import { memo } from "react";
import HeaderLayout from "../main-layout/components/header";
import classNames from "classnames";
import "./body.css";

export const Body = memo(
  ({ children, header, scroll, disabledPaddings, filters }) => {
    return (
      <div
        className={"lf-body"}
        style={{
          backgroundColor: "#fff",
          maxHeight: "100vh",
          width: "100%",
          overflow: "hidden",
          cursor: "default",
        }}
      >
        {header?.title && (
          <HeaderLayout
            title={header.title}
            search={header.search}
            create={header.create}
            isBold={header.isBold}
            filters={filters}
            period={header.period}
          />
        )}
        <div
          className={classNames(
            "lf-content",
            { "lf-content--scroll": scroll },
            { "lf-content--no-paddings": disabledPaddings }
          )}
        >
          {children}
        </div>
      </div>
    );
  }
);
