import classNames from "classnames";
import { memo } from "react";
import { Chips } from "../../chips/chips";

export const TableCellChips = memo(({ color, text, img }) => {
  return (
    <div className={classNames("lf-table-cell-chips")}>
      <Chips text={text} color={color} />
    </div>
  );
});
