import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { Checkbox, Divider, Dropdown, Input, Tag } from "antd";
import { useEffect, useState } from "react";
import { truncateText } from "../../../common/utils/tranculateText";

const MultipleFilter = ({ field, onChange, options, values }) => {
  const [search, setSearch] = useState();
  const [dropdownOptions, setDropdownOptions] = useState(options);
  const [open, setOpen] = useState(true);

  const updateSearch = (e) => {
    const searchValue = e.target.value;
    if (searchValue && searchValue.trim().length) {
      setSearch(searchValue);
    } else {
      setSearch(null);
    }
  };

  useEffect(() => {
    setDropdownOptions(
      search
        ? options.filter((option) => {
            const name =
              option.name || `${option.firstName} ${option.lastName}`;
            return name.toLowerCase().includes(search.toLowerCase());
          })
        : options
    );
  }, [search, options]);

  const getDropdownSearch = (name, id) => ({
    key: id,
    label: (
      <div>
        <div style={{ display: "flex" }}>
          <SearchOutlined />
          <Input
            bordered={false}
            value={search}
            placeholder={name}
            onChange={updateSearch}
            maxLength={255}
          />
        </div>
        <Divider style={{ margin: 0 }} />
      </div>
    ),
  });

  const getDropdownItem = (name, id) => ({
    key: id,
    label: (
      <div
        key={id}
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => onChange(field.id, id)}
        role="presentation"
      >
        <Checkbox checked={values.includes(id)} />
        <span style={{ marginLeft: 10 }}>{truncateText(name, 55)}</span>
      </div>
    ),
  });

  const filters = () => {
    const content = [];

    content.push(getDropdownSearch("Search", "dropdown-search"));

    dropdownOptions.forEach((item) =>
      content.push(
        getDropdownItem(
          item.name || `${item.firstName} ${item.lastName}`,
          item.id
        )
      )
    );

    return content;
  };

  const generateTagString = () => {
    if (!values.length) return "";
    let string = "";

    for (let i = 0; i < values.length && i < 3; i = i + 1) {
      const { name, firstName, lastName } =
        dropdownOptions.filter((element) => element.id === values[i])[0] || {};
      if (i !== 2) {
        string = i
          ? `${string}, ${name || `${firstName} ${lastName}`}`
          : name || `${firstName} ${lastName}`;
      } else {
        string = `${string}, ${values.length - 2} more`;
      }
    }

    return string;
  };

  const openDropdown = () => {
    setOpen(true);
  };

  const close = (visible) => {
    if (!visible) {
      setOpen(visible);
    }
  };

  return (
    <Dropdown
      menu={{ items: filters(), onClick: (e) => e.preventDefault?.() }}
      overlayStyle={{
        maxHeight: 320,
        overflow: "auto",
        boxShadow: "0px 20px 140px 0px rgba(0, 0, 0, 0.15)",
      }}
      trigger={["click"]}
      open={open}
      onOpenChange={(visible) => close(visible)}
    >
      <Tag
        style={{
          fontSize: 14,
          border: "none",
          background: "rgba(108, 108, 137, 0.08)",
          padding: "5px 10px",
          cursor: "pointer",
        }}
        onClick={openDropdown}
      >
        <span style={{ fontWeight: 600 }}>{field.name} : </span>
        <span style={{ color: "rgba(108, 108, 137, 1)" }}>
          {truncateText(generateTagString(), 30)}
        </span>
        <span
          style={{
            cursor: "pointer",
            color: "rgba(178, 178, 199, 1)",
            marginLeft: 10,
          }}
          onClick={() => onChange(field.id)}
          role="presentation"
        >
          <CloseOutlined style={{ fontSize: 12 }} />
        </span>
      </Tag>
    </Dropdown>
  );
};

export default MultipleFilter;
